.settings {
  width: 60%;
  margin: 0 20% 48px;
  height: 100vh;
}
.settingCluster {
  border: 1px solid rgba(var(--theme-divider));
  box-sizing: border-box;
  width: 100%;
  height: max-content;
}
.settingContent {
  /* margin-top: 36px; */
  display: flex;
  max-width: 90%;
  margin: 36px 10%;
  justify-content: space-between;
}
.settingSwitch {
  width: 60%;
  position: relative;
}
/* .settingSwitchbtn {
  position: absolute;
  top: 0;
  right: 0;
} */
.settingSwitchCluster {
  display: flex;
  justify-content: space-between;
}
.settingDropdownCluster {
  display: flex;
  justify-content: space-between;
}
.settingThemeCluster {
  display: flex;
  justify-content: space-between;
}
.settingSubTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;
  margin-top: 7px;
  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingPara {
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin: auto 0 auto 0;

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingparatop {
  margin-top: 24px;
}
.settingsTitle {
  margin: 48px 0;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: 0.18px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingModePara {
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* 00. On Surface / Medium Emphasis */

  color: rgba(var(--theme-text-secondary));
}
/* #customized-select{
  display: none;
} */
.menuitem {
  color: rgba(var(--theme-text-primary));
}

.settings-select{
  height: 30px !important;
  width: 42px;
  overflow: hidden;
  padding: 4px 12px !important;
}

.settingDropdownCluster > .MuiInputBase-root > .MuiSelect-icon {
  display: none;
}
.settingDropdownCluster > .MuiInputBase-root > .MuiSelect-root {
  color: rgba(var(--theme-text-primary));
}
@media only screen and (max-width: 1554px) {
  .settingThemeCluster {
    flex-direction: column;
  }
  .settingCluster {
    height: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .settingTheme {
    margin-top: 20px;
  }
  .settings {
    width: 90%;
    margin: 0 5%;
  }
}
