.comment-button-text {
    color: var(--theme-secondary-main)
}

.comments-root {
    margin-top: 0px;
}

.comment-handle-panel {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px
}

.reply-tag {
    padding: 2px 8px;
    margin-right: 4px;
    border-radius: 2px;
    white-space: nowrap;
    max-width: 260px;
    font-size: 14px;
    color: rgba(var(--theme-text-secondary));
    background-color: rgba(var(--theme-divider));
}

@media only screen and (min-width: 1200px) {
    .comments {
        min-height: 500px;
    }

    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        align-items: center;
        grid-template-columns: 40px auto;
        grid-column-gap: 16px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 100%;
        height: 36px;
        background: rgba(var(--theme-surface-main));
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 1200px) {
    .comments-root {
        margin-top: 32px;
    }

    .comment-handle-panel {
        margin-right: 32px
    }

    .comments {
        padding-left: 10px;
    }

    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        grid-template-columns: 40px auto;
        grid-column-gap: 10px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 95%;
        height: 36px;
        background: rgba(33, 33, 33, 0.08);
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 468px) {
    .comments {
        padding-left: 10px;
    }

    .comments-root {
        margin-top: 16px;
    }


    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        grid-template-columns: 40px auto;
        grid-column-gap: 10px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 95%;
        height: 36px;
        background: rgba(33, 33, 33, 0.08);
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }

    .comment-handle-panel {
        margin-right: 16px
    }
}