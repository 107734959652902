.course-other {
    margin-top: 24px !important;
    width: 100%;
    border-bottom: 1px solid rgba(var(--theme-divider));
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px !important;
    grid-row-gap: 28px;
}

.course-other > * {
    text-align: center;
    width: 32%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.course-detail > *:nth-child(1) {
    font-size: 10px;
    height: 16px;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(var(--theme-text-secondary));
    text-transform: uppercase;
}

.course-detail> *:nth-child(2) {
    height: 24px;
    min-width: 24px;
    color: rgba(var(--theme-text-primary));
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    font-weight: 500;
    margin: 4px auto;
}

.course-detail > *:nth-child(3) {
    color: rgba(var(--theme-text-primary));
    font-size: 14px;
    height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 20px;
}

.course-instructor {
    flex-grow: 1;
}

.faculty-profile-stack{
    display: inline-flex;
    flex-direction: row-reverse;
    margin: 3px auto;
}

.avatar {
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    width: 26px;
}
.avatar:not(:last-child) {
    margin-left: -10px;
  }
  

.instructor-profile-img{
    width: 100%;
    display: block;
    border-radius: 100%;  
}

.course-instructor > *:nth-child(1){
    font-size: 10px;
    height: 16px;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(var(--theme-text-secondary));
    text-transform: uppercase;
}

.course-instructor-name{
    color: rgba(var(--theme-text-primary));
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 20px;
}

.faculty-concat-ampersand{
    color: rgba(var(--theme-text-secondary));
}



.partition-line {
    width: 1px !important;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.12);
    position: relative;
    top: 8px; 
}

@media (min-width: 768px) {
    .partition-line:nth-child(6){
        display: none;
    }
}

#course-language .partition-line, #course-duration .partition-line {
    display: none;
}

@media screen and (max-width: 1000px) {
    .course-other > *{
        width: 120px;
    }
} 
