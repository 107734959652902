.formGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 22px;
  grid-column-gap: 22px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 468px) {
  .formGridContainer {
    display: flex;
    flex-direction: column;
    grid-row-gap: 17px;
  }
}
