.content-thumbnail {
  position: relative;
}

.content-thumbnail span {
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.notification-content {
  top: 60px;
}

.notification-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-delete{
  margin-right: 16px;
}

.notification-title {
  width: 83px;
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(var(--theme-text-primary));
  margin: 20px auto 16px 20px;
}

.hori-line {
  border-bottom: 1px solid rgba(var(--theme-divider));
}

.content-template {
  width: 360px;
  padding-left: 4px;
}

.hovering:hover {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.content-flex {
  display: flex;
  align-items: center;
}

.dot-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  min-width: 4px;
}

.nesoIcon-content {
  margin-left: 6px;
  width: 40px;
  height: 40px;
}

.nesoIcon-content-img {
  width: 40px;
  height: 40px;
}

.right-content {
  display: flex;
  border-bottom: 1px solid rgba(var(--theme-divider));
  margin-left: 16px;
}

.one-by-one-flex {
  display: flex;
  flex-wrap: wrap;
  width: 172px;
  margin: 12px 16px 12px 0px;
}

.one {
  flex: 0 0 100%;
}

.category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(var(--theme-text-secondary))
}

.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-primary));
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(var(--theme-text-secondary));
}

.template-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-block {
  position: absolute;
  top: 25%;
  left: 10%;
}

.template-title{
  color: white;
  font-size: 10px;
  font-weight: 500;
  max-width: 80px;
}

.template-chapter{
  color: #F3994B;
  font-size: 6px;
  letter-spacing: 0.05em;
  margin-bottom: 2px;
}