.CourseCard {
    transition: top ease 0.5s;
    position: relative;
    top: 0;
    cursor: pointer;
}

.CourseCard:hover {
    border-radius: 4px;
    top: -10px;
}

.CourseCard-Img {
    padding-top: 100%;
    border-radius: 4px;
    position: relative;
}


@media only screen and (min-width: 1300px) {
    
    .CourseCard-Title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    .CourseCard-Description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 1300px) {


    .CourseCard-Title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .CourseCard-Description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(var(--theme-text-secondary));
    }
}

@media only screen and (max-width: 600px) {
    .CourseCard {
        width: 120px;
        box-shadow: none;
        transition: top ease 0.5s;
        position: relative;
        top: 0;
        cursor: pointer;
    }


    .CourseCard:hover {
        border-radius: 4px;
        top: 0px;
    }

    .CourseCard-Img {
        padding-top: 100%;
        border-radius: 4px;
    }


    .CourseCard-Title {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
    }

    .CourseCard-Description {
        display:none;
    }
}