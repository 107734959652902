#breadCrumb-button{
    margin: 0px -12px;
}

#notesview-section-header{
    margin-left: 47px;
    margin-top: 24px;
}

.notes-view-details {
    margin-left: 47px;
    margin-top: 48px;
    width: 46.73vw;
    max-width: 673px;
    border-bottom: 1px solid rgba(var(--theme-divider));
    padding-bottom: 32px;
}

.notes-view-details p {
    font-size: 34px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}

.notes-view-icons {
    max-width: 120px;
    display: flex;
    justify-content: space-between;
}

.notes-view-tablet {
    display: none;
}

.notes-view-pdf {
    padding: 36px;
}

.empty-notes-view {
    width: 49.58vw;
    height: 27.91vw;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.12);
}

.ppt-view-watch-video {
    border-bottom: 1px solid rgba(var(--theme-divider));
    margin-left: 48px;
    max-width: 682px;
    width: 47.36vw;
    padding-bottom: 64px;
}

.ppt-view-watch-video p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 24px;
}

.ppt-view-watch-video img {
    width: 47.43vw;
    height: 26.66vw;
    max-width: 683px;
    max-height: 384px;
}

.pdf-view-comment-section {
    margin: 32px 12.22vw 0 47px;
}


@media screen and (max-width: 1000px) {

    .notes-view-pdf {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .notes-view-tablet {
        display: block;
        padding: 0 4.16vw 18px 4.16vw;
        border-bottom: 1px solid rgba(var(--theme-divider));
    }

    #notes-section-scrolled {
        position: fixed;
        top: 64px;
        left: 0;
        right: 0;
    }

    #notesview-section-header {
        margin: 24px 0;
    }

    .ppt-pages {
        margin: 0 70px 0 0;
    }

    .notes-view-tablet-details {
        width: 100%;
        justify-content: space-between;
        display: flex;
        background-color: white;;
    }

    .notes-icons {
        width: 72px;
        display: flex;
        justify-content: space-between;
    }

    .notes-view-header {
        display: none;
    }

    #first-span {
        max-width: none;
    }

    .empty-notes-view {
        width: 91.66vw;
        height: 52.34vw;  
    }

    .ppt-view-watch-video {
        margin-left: 4.16vw;
        width: 91.66vw;
        height: 52.34vw;
    }

    .ppt-view-watch-video img {
        width: 100%;
        height: 100%;
    }

    .pdf-view-comment-section {
        margin: 32px 0 0 4.16vw;
    }

    .comments {
        padding-left: 0;
    }


}
