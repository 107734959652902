.continueVideo-background {
  height: 328px;
  border-radius: 4px;
  padding-top: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}
.continueVideo-flex {
  display: inline-block;
}
.welcome-user-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(var(--theme-divider));
  border-radius: 4px;
  flex-grow: 1;
  height: 360px;
}

.welcome-user-inner-container {
  margin-left: 56px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.welcome-user-title {
  margin-bottom: 16px;
  color: rgba(var(--theme-text-primary));
}

.welcome-user-description {
  margin-bottom: 48px;
  color: rgba(var(--theme-text-secondary));
}

.welcome-user-button {
  background: rgba(1, 135, 134, 0.12);
  border-radius: 4px;
}

.welcome-user-button:hover {
  background: rgba(1, 135, 134, 0.12);
  border-radius: 4px;
}

@media only screen and (min-width: 1000px) {
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    padding: 24px 0 0 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: white;
    /* rgba(var(--theme-text-primary)) */
  }
  .continueVideo-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .continueVideo {
    width: 100%;
    height: 360px;
    border-radius: 4px;
    background-position-y: center;
    background-size: cover;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .chapter-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #03DAC5;
    padding-left: 32px;
  }
  .chapter-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: white;
    /*  rgba(var(--theme-text-primary)) */
    ;
    padding-top: 12px;
    width: 360px;
    padding-left: 32px;
  }
  .grow-description {
    flex-grow: 1;
  }
  .continueVideo-flex {
    width: 100%;
    margin-bottom: 32px;
  }
  .continueVideo-description {
    display: flex;
    justify-content: stretch;
    align-items: center;
    column-gap: 16px;
  }
  .remaining-time {
    background-color: #FFFFFF  /* rgba(var(--theme-background-default)) */;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.6) /* rgba(var(--theme-text-secondary)) */;
  }
  .play-icon {
    padding-right: 24px;
  }
  .small-screen-flex {
    display: none;
  }
  .box-ad {
    width: 328px;
    height: 360px;
    background-color: lightgray;
  }
}

@media only screen and (max-width: 1000px) {
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    padding: 24px 0 0 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: white;
  }
  /* .setting-width {
    width: 100%;
  } */
  .continueVideo-container {
    /* display: flex; */
    justify-content: space-between;
  }
  .continueVideo-description {
    display: flex;
    justify-content: stretch;
    align-items: center;
    column-gap: 16px;
  }
  .continueVideo {
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position-y: center;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .chapter-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #03DAC5;
    padding-left: 32px;
  }
  .chapter-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    padding-top: 12px;
    width: 270px;
    padding-left: 32px;
    color: white;
  }
  .grow-description {
    flex-grow: 1;
  }
  .continueVideo-flex {
    width: 100%;
    margin-bottom: 32px;
  }
  .remaining-time {
    background-color: #FFFFFF/* rgba(var(--theme-background-default)) */
    ;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.6)/* rgba(var(--theme-text-secondary)) */
    ;
  }
  .play-icon {
    padding-right: 24px;
  }
  .small-screen-flex {
    display: none;
  }
  .box-ad {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .continueVideo {
    height: 268px;
  }
  .continueVideo-background {
    height: 236px;
  }
  .chapter-title {
  }
}

@media only screen and (max-width: 600px) {
  .continueVideo-background {
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    display: none;
  }
  .continueVideo-container {
    display: block;
    width: 100%;
  }
  .continueVideo-description {
    margin: 0px;
  }
  .continueVideo {
    width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .small-screen-remain-time {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(var(--theme-text-secondary));
    margin-top: 12px;
    flex: 0 1 100%;
  }
  .small-screen-remain-title {
    max-width: 284px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-primary));
  }
  .flex-grow-small-screen {
    flex-grow: 1;
  }
  .small-screen-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .small-screen-play-icon {
    height: 36px;
    width: 36px;
    align-self: center;
  }
  .chapter-title {
    display: none;
  }
  .continueVideo-background {
    height: auto;
    padding-bottom: 0;
  }
  .chapter-description {
    display: none;
  }
  .remaining-time {
    display: none;
  }
  .play-icon {
    display: none;
  }
  .box-ad {
    display: none;
  }
  .welcome-user-container {
    height: auto;
  }
  .welcome-user-inner-container{
    margin-left: 32px;
  }
}