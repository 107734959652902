@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sniglet&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@700&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

input:-webkit-autofill, input:autofill , input:autofill:hover, input:autofill:focus, input:autofill:active  {
  box-shadow: 0 0 0 100px #E8F0FE inset !important;
  -webkit-text-fill-color: #000 !important;
}

input:-moz-a

::-webkit-scrollbar {
  background: rgba(var(--theme-background-default));
  border-radius: 4px;
  height: 8px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-scrollbar-thumb);
  border-radius: 4px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}
a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Global style overrides */

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

@media (min-width:600px) {
  .MuiContainer-root {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width:1176px) {
  .MuiContainer-root {
    padding-left: 10px;
    padding-right: 10px;
  }
}
* {
    transition: background-image 225ms ease-in,  margin-top 225ms ease, background-color 225ms ease-in,color 225ms ease-in,background 225ms ease-in;
    scrollbar-width: thin;
}

.footer-shortlink-header {
    color: rgba(var(--theme-text-primary));
}

.copyright {
    color: rgba(var(--theme-text-disabled));
    margin-top: 7px
}

.footer-container {
    box-shadow: none;
}

.MuiGrid-spacing-xs-2 {
    margin: 0;
}

.Made-Footer {
    margin-top: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
}

.Footer-link {
    text-decoration: none;
    color: rgba(var(--theme-text-secondary));
}

.First-grid {
    padding-top: 64px;
}

.Social-link {
    margin-right: 24px;
}

.Footer-Links p {
    margin-top: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
}

@media only screen and (min-width: 1000px) {
    .footer-container-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    .footer-container-padding {
        padding: 0 0 60px 16px;
    }
}

@media only screen and (max-width: 478px) {
    .footer-container-padding {
        padding: 0 0 60px 16px;
    }
}
.NavBar-primary {
  color: rgba(var(--theme-text-secondary));
  box-shadow: none;
  border-bottom: 1px solid rgba(var(--theme-divider));
  height: 60px;
  margin: 0;
}

.Header-Container {
  padding: 0 55px 0 71px;
}

#open.header-search-container {
  width: 100%;
}

.header-search-container {
  transition: width 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  flex-direction: row-reverse;
}

.header-input-bar {
  max-width: 360px;
  z-index: 5;
  background: var(--theme-background-surface);
  position: absolute;
  overflow: hidden;
  width: 0%;
  transition: width 0.3s ease-out;
}

#open.header-input-bar {
  width: 100%;
  overflow: unset;
}

.header-search-bar {
  display: flex;
  box-sizing: border-box;
  height: 36px;
  background-color: rgba(var(--theme-surface-main));
  padding: 6px 12px;
  position: relative;
  border-radius: 4px;
}

.header-search-bar input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: rgba(var(--theme-text-primary));
  padding-left: 8px;
  padding-right: 8px;}

.header-search-bar input:focus {
  outline: none;
}

.header-search-bar img:nth-child(2) {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.header-search-bar-close {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.header-suggestions {
  max-width: 100%;
  position: fixed;
  right: 0;
  left: 0;
  border-bottom: 1px solid rgba(var(--theme-divider));
  border-top: none;
  top: 56px;
  background: var(--theme-background-surface);
  border-radius: 0 0 4px 4px;
}

.suggestion-item {
  height: 24px;
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.suggestion-item svg {
  margin: 0 10.93px 0 13.5px;
}

.suggestion-item span {
  font-size: 16px;
  color: rgba(var(--theme-text-primary));
  letter-spacing: 0.15px;
}

@media screen and (max-width: 480px) {
  .header-input-bar {
    max-width: none;
    position: absolute;
    align-items: center;
    z-index: 1200;
    background: var(--theme-background-surface);
    border-bottom: 1px solid rgba(var(--theme-divider));
    display: flex;
    flex-grow: 1;
    height: 55px;
    width: 100%;
  }
  .header-search-bar {
    width: 100%;
  }
  .header-search-container {
    position: absolute;
    height: calc(100% - 1px);
    width: 0%;
  }
  .header-suggestions {}
}
.Secondary-outline1 {
  width: 93px;
  height: 36px;
  border: 1px solid var(--theme-secondary-main) !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px !important;
  text-transform: uppercase;
  color: var(--theme-secondary-main) !important;
  margin: 10px;
}

.Secondary-outline1:hover {
  width: 93px;
  height: 36px;
  border: 1px solid var(--theme-secondary-main);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgba(1, 135, 134, 0.07);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--theme-secondary-main);
}

.Secondary-filled {
  width: 93px;
  height: 36px;
  background: var(--theme-secondary-main) !important;
  border-radius: 4px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px !important;
  text-transform: uppercase;
  color: rgba(var(--theme-text-button-filled)) !important;
  box-shadow: none;
  margin: 10px;
}

.Secondary-filled:hover {
  width: 93px;
  height: 36px;
  background: #026363;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: rgba(var(--theme-text-button-filled));
  box-shadow: none;
}

.content-thumbnail {
  position: relative;
}

.content-thumbnail span {
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.notification-content {
  top: 60px;
}

.notification-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-delete{
  margin-right: 16px;
}

.notification-title {
  width: 83px;
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(var(--theme-text-primary));
  margin: 20px auto 16px 20px;
}

.hori-line {
  border-bottom: 1px solid rgba(var(--theme-divider));
}

.content-template {
  width: 360px;
  padding-left: 4px;
}

.hovering:hover {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.content-flex {
  display: flex;
  align-items: center;
}

.dot-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  min-width: 4px;
}

.nesoIcon-content {
  margin-left: 6px;
  width: 40px;
  height: 40px;
}

.nesoIcon-content-img {
  width: 40px;
  height: 40px;
}

.right-content {
  display: flex;
  border-bottom: 1px solid rgba(var(--theme-divider));
  margin-left: 16px;
}

.one-by-one-flex {
  display: flex;
  flex-wrap: wrap;
  width: 172px;
  margin: 12px 16px 12px 0px;
}

.one {
  flex: 0 0 100%;
}

.category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(var(--theme-text-secondary))
}

.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-primary));
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(var(--theme-text-secondary));
}

.template-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-block {
  position: absolute;
  top: 25%;
  left: 10%;
}

.template-title{
  color: white;
  font-size: 10px;
  font-weight: 500;
  max-width: 80px;
}

.template-chapter{
  color: #F3994B;
  font-size: 6px;
  letter-spacing: 0.05em;
  margin-bottom: 2px;
}
.authoredCoursesContainer {
  display: grid;
  grid-template-columns: 176px 176px 176px;
  grid-column-gap: 20px;
  grid-row-gap: 32px;
}

@media only screen and (max-width: 972px) {
  .authoredCoursesContainer {
    grid-template-columns: 176px 176px;
  }
}

@media only screen and (max-width: 768px) {
  .authoredCoursesContainer {
    grid-template-columns: 161px 161px 161px 161px;
  }
}

@media only screen and (max-width: 740px) {
  .authoredCoursesContainer {
    grid-template-columns: 161px 161px 161px;
  }
}

@media only screen and (max-width: 600px) {
  .authoredCoursesContainer {
    margin-top: 16px !important;
    overflow-x: scroll;
    display: flex;
    grid-column-gap: 16px;
    scrollbar-width: none;
  }
  .authoredCoursesContainer::-webkit-scrollbar {
    display: none;
  }
}

.CourseCard {
    transition: top ease 0.5s;
    position: relative;
    top: 0;
    cursor: pointer;
}

.CourseCard:hover {
    border-radius: 4px;
    top: -10px;
}

.CourseCard-Img {
    padding-top: 100%;
    border-radius: 4px;
    position: relative;
}


@media only screen and (min-width: 1300px) {
    
    .CourseCard-Title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    .CourseCard-Description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 1300px) {


    .CourseCard-Title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .CourseCard-Description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(var(--theme-text-secondary));
    }
}

@media only screen and (max-width: 600px) {
    .CourseCard {
        width: 120px;
        box-shadow: none;
        transition: top ease 0.5s;
        position: relative;
        top: 0;
        cursor: pointer;
    }


    .CourseCard:hover {
        border-radius: 4px;
        top: 0px;
    }

    .CourseCard-Img {
        padding-top: 100%;
        border-radius: 4px;
    }


    .CourseCard-Title {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
    }

    .CourseCard-Description {
        display:none;
    }
}
.course-other {
    margin-top: 24px !important;
    width: 100%;
    border-bottom: 1px solid rgba(var(--theme-divider));
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px !important;
    grid-row-gap: 28px;
}

.course-other > * {
    text-align: center;
    width: 32%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.course-detail > *:nth-child(1) {
    font-size: 10px;
    height: 16px;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(var(--theme-text-secondary));
    text-transform: uppercase;
}

.course-detail> *:nth-child(2) {
    height: 24px;
    min-width: 24px;
    color: rgba(var(--theme-text-primary));
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    font-weight: 500;
    margin: 4px auto;
}

.course-detail > *:nth-child(3) {
    color: rgba(var(--theme-text-primary));
    font-size: 14px;
    height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 20px;
}

.course-instructor {
    flex-grow: 1;
}

.faculty-profile-stack{
    display: inline-flex;
    flex-direction: row-reverse;
    margin: 3px auto;
}

.avatar {
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    width: 26px;
}
.avatar:not(:last-child) {
    margin-left: -10px;
  }
  

.instructor-profile-img{
    width: 100%;
    display: block;
    border-radius: 100%;  
}

.course-instructor > *:nth-child(1){
    font-size: 10px;
    height: 16px;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(var(--theme-text-secondary));
    text-transform: uppercase;
}

.course-instructor-name{
    color: rgba(var(--theme-text-primary));
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 20px;
}

.faculty-concat-ampersand{
    color: rgba(var(--theme-text-secondary));
}



.partition-line {
    width: 1px !important;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.12);
    position: relative;
    top: 8px; 
}

@media (min-width: 768px) {
    .partition-line:nth-child(6){
        display: none;
    }
}

#course-language .partition-line, #course-duration .partition-line {
    display: none;
}

@media screen and (max-width: 1000px) {
    .course-other > *{
        width: 120px;
    }
} 

.recentFacultyVideoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 26px;
}

@media only screen and (max-width: 768px) {
  .recentFacultyVideoContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .recentFacultyVideoContainer {
    grid-template-columns: repeat(6, calc(100% / 1.3));
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .recentFacultyVideoContainer::-webkit-scrollbar{
    display: none;
  }
}


@keyframes videoAnimation {
    0% {
        left: 30px
    }

    100% {
        left: 0px;
    }
}



.clicked-more {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 32px;
    width: 13.81vw;
/*     max-width: 199px;
 */    align-items: flex-start;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid rgba(var(--theme-divider));
    transition: all 0.2s;
    transform: scale(0);
    background: var(--theme-background-surface);
    z-index: 16;
    transform-origin: top right;
}

.clicked-more > * {
    display: flex;
    align-items: center;
}

.clicked-more-item {
    padding-left: 16px;
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
}

.clicked-more-item div {
    margin-left: 21px;
    color: rgba(var(--theme-text-primary));
    font-size: 16px;
    letter-spacing: 0.15px;
}

#more-clicked {
    transform: scale(1);
}



@media screen and (max-width: 1344px) {
    .clicked-more {
        width: 15.81vw;
        padding: 10px;
    }

    .clicked-more-item span {
        font-size: 14px;
    }

    .clicked-more-item img {
        max-width: 14px;
    }
}

@media screen and (max-width: 1000px) {

    .clicked-more {
        width: 25.91vw;
        padding: 16px;
    }

    .clicked-more-item span {
        font-size: 16px;
    }

    .clicked-more-item img {
        max-width: auto;
    }
}

@media screen and (max-width: 690px) {
    .clicked-more {
        width: 26.91vw;
        padding: 10px;
    }

    .clicked-more-item span {
        font-size: 14px;
    }

    .clicked-more-item img {
        max-width: 14px;
    }
}

@media screen and (max-width: 630px) {
  
    .clicked-more {
        display: none;
    }

    #transparent-section {
        display: none;
    }
}








/* .Support {
  width: 75%;
  margin: 0 12.5%;
} */

.supportContent>p:nth-child(1) {
  color: rgba(var(--theme-text-primary));
  font-size: 34px;
  margin-top: 72px;
  line-height: 36px;
  /* identical to box height, or 106% */
}

.supportRightCluster>img {
  margin-left: 10%;
}

.supportContent>p:nth-child(2) {
  width: 65%;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  margin-top: 24px;
  margin-bottom: 171px;

  letter-spacing: 0.15px;

  /* 00. On Surface / Medium Emphasis */

  color: rgba(var(--theme-text-secondary));
}

.supportRightCluster {
  margin-top: 111px;
  /* display: flex;
  flex-direction: column; */
}

.supportBox {
  width: 100%;
  height: 23%;
  border: 1px solid rgba(var(--theme-divider));
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  transition: transform 300ms ease;
}

.supportBox:nth-child(1) {
  margin-top: 82px !important;
}

.supportBox:nth-child(3) {
  cursor: default;
}

.supportBox:nth-child(3):hover {
  transform: none;
}

.supportBox:hover {
  transform: scale(1.025);
}

.supportBoxContent {
  margin: 0 32px;
}

.supportBoxContent>img:nth-child(1) {
  margin-top: 32px;
}

/* .supportBoxContent > svg:nth-child(2) {  
  position: absolute;
  top: 20px;
  right: 20px;
} */
.supportBoxContent>a {
  position: absolute;
  top: 20px;
  right: 20px;
}

.supportBoxBody {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  letter-spacing: 0.25px;
  color: rgba(var(--theme-text-secondary));
}

.supportBoxContent>p:nth-child(2) {
  margin-top: 16px !important;
}

.support-mode-title {
  color: rgba(var(--theme-text-primary));
}

.paytmWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#PaytmQR {
  background: white;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media only screen and (max-width: 468px) {
  .supportBox {
    width: 100% !important;
    height: 33% !important;
  }

  .supportRightCluster>img {
    height: 148px;
    width: 132px;
  }

  .supportContent>p:nth-child(1) {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.15px;

  }

  .supportBox:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 16px !important;
  }

  .supportBox:nth-child(3) {
    margin-left: 0 !important;
    margin-top: 16px !important;
  }

  .supportLeftCluster {
    justify-content: center;
  }

  .supportLeftCluster {
    margin-top: 32px !important;
  }

}

@media only screen and (max-width: 768px) {
  .supportBox:nth-child(1) {
    margin-top: 0px !important;
  }

  .Support {
    width: 90%;
    margin: 0 5%;
  }

  .supportBox {
    width: 46.5%;
    /* min-width: 40%; */
    margin-top: 0;
    height: 45%;
  }

  .supportBox:nth-child(3) {
    height: 212px;
  }

  .supportBoxContent {
    margin: 0 29.95px;
  }

  .supportBoxBody:nth-child(3) {
    width: 154px !important;
  }

  .supportBox:nth-child(2) {
    margin-left: 5.67%;
  }

  .supportBox:nth-child(3) {
    margin-top: 20px;
    margin-bottom: 56px;
  }

  .supportRightCluster {
    margin-top: 56px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .supportRightCluster>img {
    margin-left: 0;
  }

  .supportContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .supportContent>p:nth-child(1) {
    margin-top: 48px;
  }

  .supportLeftCluster {
    margin-top: 56px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .supportContent>p:nth-child(2) {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

/* @media screen and (max-width: 5000px) and (min-width: 1800px) {
  .Support {
    width: 50%;
    margin: 0 25%;
  }
} */
.titleContact {
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  /* identical to box height, or 106% */

  /* 00. On Surface / High Emphasis */
  text-align: center;
  color: rgba(var(--theme-text-primary));
}
.contactCluster {
  margin-top: 64px;
  margin-bottom: 60px;
}
.contactContent {
  /* margin-top: 39px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0px 25%;
}
.nameContact {
  margin-top: 36px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactInputMsg {
  outline: none;
  border: 1px solid rgba(var(--theme-divider));
  background-color: rgba(var(--theme-background-default));
  color: rgba(var(--theme-text-primary));

  box-sizing: border-box;
  width: 100%;
  height: 155px;
  resize: none;
  padding: 10px;
}
.contactInput {
  background-color: rgba(var(--theme-background-default));
  color: rgba(var(--theme-text-primary));
  outline: none;
  border: 1px solid rgba(var(--theme-divider));
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
  resize: none;
  padding: 10px;
}
.contactLabel {
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15px;

  /* 00. On Surface / Medium Emphasis */
  font-style: normal;
  font-weight: normal;
  color: rgba(var(--theme-text-secondary));
}
.messageContact {
  height: 190px;
  margin-top: 36px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactfirstcluster {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.contactfirstcluster > div {
  width: 48%;
}
@media only screen and (max-width: 768px) {
  .contactContent {
    width: 95%;
    margin: 0 2.5%;
  }
}
@media screen and (max-width: 5000px) and (min-width: 1800px) {
  .contactContent {
    width: 40%;
    margin: 0px 30%;
  }
}

.recruitModal {
  margin: 56px 5%;
}
.recruitModalTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* text-align: center; */
  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.recruitModalPara {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin-top: 16px;
  letter-spacing: 0.15px;

  /* 00. On Surface / Medium Emphasis */

  color: rgba(var(--theme-text-secondary));
}

.recruitModalBtn {
  margin: 30px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.recruitTitleWrapper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recruitTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  color: rgba(var(--theme-text-primary));
  text-align: center;
}
.recruitSubTitle {
  margin-top: 24px;

  width: 648px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-secondary));
}
.recruitCard {
  height: 176px;
  width: 100%;
  border: 1px solid rgba(var(--theme-divider));
  box-sizing: border-box;
  text-align: start;
  display: flex;
  justify-content: start;
  align-items: center;
}

.active{
  border: 1px solid var(--theme-secondary-main);
  transition: transform 300ms ease;
}

.active:hover{
  transform: scale(1.025);
}

.recruitCardsWrapper {
  /* width: 65%; */
  margin: 88px 0 79px;
}
/* .DisabledRecruitCard {
  cursor: default;
} */
/* .disabledRecruitContent {
  color: theme.palette.text.disabled !important;
} */
.recruitContent {
  /* margin-top: 32px; */
  margin-left: 32px;
}
.recruitContentTitle {
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-primary));
}
.recruitContentBody {
  width: 174px;
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(var(--theme-text-secondary));
}
@media only screen and (max-width: 768px) {
  .recruitSubTitle {
    width: 62%;
  }
  .recruitCardsWrapper {
    width: 90%;
    margin: 48px 5% 63px;
  }
}
@media only screen and (max-width: 468px) {
  .recruitTitleWrapper {
    margin-top: 32px;
  }
  .recruitCardsWrapper {
    margin-bottom: 25px !important;
  }
  .recruitSubTitle {
    width: 90%;
  }
  .recruitTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.15px;
  }
  .recruitSubTitle {
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;
    letter-spacing: 0.25px;
  }
}

@media only screen and (min-width: 1200px) {
  .books-details-section {
    margin-top: 40px;
  }

  .books-section-header {
    font-size: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }

  .books-details-book-section {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1200px) {
  .books-details-section {
    width: 100vw;
    padding: 0 0 0 32px;
    margin-top: 20px;
  }

  .books-section-header {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
    display: flex;
  }

  .books-details-book-section {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 468px) {
  .books-details-section {
    width: 100vw;
    padding: 0;
    margin-top: 20px;
  }

  .books-section-header {
    color: rgba(var(--theme-text-primary));
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .books-details-book-section {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1200px) {
  .book-section {
    margin: 0 20px 24px 0;
    width: 274px;
    transition: top ease 0.5s;
    position: relative;
    top: 0;
    cursor: pointer;
  }

  .book-section:hover {
    border-radius: 4px;
    top: -10px;
  }

  .book-thumbnail {
    width: 274px;
    height: 354px;
    background-color: rgba(var(--theme-divider));
  }

  .book-author {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }

  .book-name {
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    line-height: 24px;
    margin: 4px 0 18px 0;
  }

  .book-rating {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }


  .b-rcount {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }

}

@media only screen and (max-width: 1200px) {
  .book-section {
    margin: 0 20px 24px 0;
    width: 221px;
    transition: top ease 0.5s;
    position: relative;
    top: 0;
    cursor: pointer;
  }

  .book-section:hover {
    border-radius: 4px;
    top: -10px;
  }

  .book-thumbnail {
    width: 100%;
    height: 261px;
    background-color: rgba(var(--theme-divider));
  }

  .book-author {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }

  .book-name {
    color: rgba(var(--theme-text-primary));
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .book-rating {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .b-rcount {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }
}

@media only screen and (max-width: 468px) {
  .book-section {
    margin: 0 10px 16px 0;
    width: 156px;
    transition: top ease 0.5s;
    position: relative;
    top: 0;
    cursor: pointer;
  }

  .book-section:hover {
    border-radius: 0px;
    top: 0;
  }

  .book-thumbnail {
    width: 100%;
    height: 184px;
    background-color: rgba(var(--theme-divider));
  }

  .book-author {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }

  .book-name {
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    line-height: 24px;
    margin: 4px 0 18px 0;
  }

  .book-rating {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .b-rcount {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
  }
}

.left-content-section {
  box-sizing: border-box;
  padding: 88px 0 32px 0;
  position: -webkit-sticky;
  position: sticky;
}

section.left-content-section {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.content-section {
  margin-bottom: 24px;
}
.content-not-hidden-section > span {
  color: rgba(var(--theme-text-primary));
}
.content-not-hidden-section {
  display: flex;
  align-items: center;
}

.content-not-hidden-section img {
  margin-right: 8px;
}

.accordion-label {
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
          user-select: none;
}

.accordion_container {
  max-width: 720px;
  margin: 0 auto;
}
.accordion_container:first-of-type {
}
.accordion_container:last-of-type {
  border-bottom: none;
}

.accordion-content {
  /* Height animation:
  only really works well if the height is close to content - too high and the animation still goes to the max-height number, messing up the timing
  For widely variable height content, best not to animate. Switch display attribute instead */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
  background-size: 260px;
  background-repeat: no-repeat;
  background-position: center;
}

.accordion-control {
  display: none;
}
.accordion-control + label {
  color: rgba(var(--theme-text-primary));
  position: relative;
  margin-left: 20px;
  padding-left: 5px;
  display: block;
  font-size: 1em;
  line-height: 1em;
  z-index: 0;
  margin-bottom: 20px;
}
.accordion-control + label:before,
.accordion-control + label:after {
  content: "";
  position: absolute;
}
.accordion-control + label:before {
  left: -19px;
  top: 3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent
    rgba(var(--theme-text-secondary));
  transition: all 0.2s ease-in;
}
.accordion-control + label:after {
  content: attr(data-label-closed);
  padding-left: 4px;
}
.accordion-control:checked + label {
  cursor: pointer;
}
.accordion-control:checked + label:before {
  transform: rotate(90deg);
}
.accordion-control:checked + label:after {
  content: attr(data-label-open);
}
.accordion-control:checked + label + .accordion-content {
  max-height: 400px;
  margin-bottom: 24px;
  padding-left: 20px;
}
.accordion-control[type="radio"] + label:before,
.accordion-control[type="radio"] + label:after {
  content: "";
  color: rgba(var(--theme-text-primary));
}
.accordion-control[type="radio"]:checked + label {
  cursor: default;
}
.accordion-control[type="radio"]:checked + label:before {
  border-left-color: rgba(var(--theme-text-secondary));
}

.content-1 {
  background-image: url("https://blog.absolutedisaster.co.uk/profile/Dark-Mills-I.jpg");
}

.content-2 {
  background-image: url("https://blog.absolutedisaster.co.uk/profile/Dark-Mills-II.jpg");
}

.content-3 {
  background-image: url("https://blog.absolutedisaster.co.uk/profile/Torquemada.jpg");
}

fieldset {
  border: none;
  padding: 0;
}

/*.MuiFormControlLabel-root {*/
/*  cursor: pointer;*/
/*  display: inline-flex;*/
/*  align-items: center;*/
/*  margin-left: 15px;*/
/*  margin-right: 16px;*/
/*  vertical-align: middle;*/
/*  -webkit-tap-highlight-color: transparent;*/
/*}*/

.books-left-section {
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 100vh;
}

.books-right-section {
    padding-left: 16px;
}



@media (hover: hover) {
    .books-left-section {
        mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 10px, black 10px);
        mask-size: 100% 20000px;
        mask-position: left bottom;
        -webkit-mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 10px, black 10px);
        -webkit-mask-size: 100% 20000px;
        -webkit-mask-position: left bottom;
        transition: mask-position 0.3s, -webkit-mask-position 0.3s;
    }

    .books-left-section:hover {
        -webkit-mask-position: left top;
        mask-position: left top;
    }
}

@media only screen and (min-width: 1200px) {
    .books-page {
        width: 1156px;
        margin: 0 auto;
        display: flex;
    }

    .books-left-section {
        display: block;
        width: 25%;
    }

    .books-right-section {
        width: 75%;
    }
}

@media only screen and (max-width: 1200px) {

    .books-section {
        width: 100%;
    }

    .books-page {
        /*display: flex;*/
        /*justify-content: center;*/
    }

    .books-right-section {
        /*display: flex;*/
        /*justify-content: center;*/
    }

    .books-left-section {
        display: none;
    }
}

@media only screen and (max-width: 468px) {
    .books-page {
        width: 100%;
        display: flex;
    }

    .books-left-section {
        display: none;
    }
}
.bm-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#smallthumbnail-selected {
  background-color: rgba(var(--theme-surface-main));
}

.SmallThumbnail{
  position: relative;
}

.Nesofuel-overlay{
  position: absolute;
  top: calc(50% - 32px);
  left: 0;
}

@media only screen and (min-width: 1200px) {
  .SmallThumbnail {
    height: 103px;
    width: 372px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1 1;
  }
  .Img-SmallThumbnail {
    width: 176px;
    height: 99px;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    margin: 0 4px 3px 0;
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 176px;
    height: 99px;
    position: relative;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .progress-bar {
    position: absolute;
    height: 4px;
    bottom: -3px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(var(--theme-divider));
  }
  #progress {
    position: absolute;
    /*width: 60%;*/
    left: 0;
    height: 4px;
    background-color: rgba(255, 65, 23, 1);
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 400;
    flex-grow: 4;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 96px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(var(--theme-text-primary));
  }
}

@media only screen and (max-width: 800px) {
  .SmallThumbnail{
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    background-color: rgba(0, 0, 0, 0.87);
    margin: 0 4px 13px 0;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .progress-bar {
    position: absolute;
    height: 4px;
    bottom: 6px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(var(--theme-divider));
  }
  #progress {
    position: absolute;
    width: 60%;
    left: 0;
    height: 4px;
    background-color: rgba(255, 65, 23, 1);
  }
  .SmallThumbnail .More-Icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .SmallThumbnail .content {
    width: 300px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 700px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin: 0 4px 13px 0;
    justify-content: center;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .SmallThumbnail .duration {
    /*width: 10px;*/
    border-bottom: 4px solid red;
    margin-top: -5px;
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    width: 300px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 400px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.08);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin: 0 4px 13px 0;
    justify-content: center;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .SmallThumbnail .duration {
    /*width: 10px;*/
    border-bottom: 4px solid red;
    margin-top: -5px;
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    width: 100%;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1200px) {
  .bookmarksHistory-main {
    min-height: 95vh;
    display: flex;
    justify-content: center;
  }

  .bookmarks-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0 0;
  }

  .bookmarks-subject-title-container {
    /* margin-bottom: 32px; */
    display: flex;
  }

  .bookmarks-subject-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 1;
    margin: 32px 0;
  }

  .expand-btn {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .visible-on-small {
    display: none;
  }

  .bookmarks-content-container {
    width: 1156px;
    /* display: flex; */
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding-bottom: 20px;
  }

  .bookmarks-subject-course-items {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(274px, 25%));
    grid-row-gap: 32px;
    row-gap: 32px;
    margin-bottom: 16px;
  }

  .bookmarks-subject-others-items {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(371px, 32.17%));
    grid-column-gap: 20px;
    column-gap: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .bookmarksHistory-main {
    min-height: 95vh;
    display: flex;
    justify-content: center;
  }

  .bookmarksHistory-main-container {
    width: 100%;
    margin: 0 16px;
  }

  .bookmarks-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }

  .bookmarks-subject-title-container {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
  }

  .bookmarks-subject-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 1;
  }

  .expand-btn {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .visible-on-small {
    display: none;
  }

  .bookmarks-content-container {
    display: grid;
    /* grid-template-columns: 274px 274px; */
    padding-bottom: 20px;
  }

  .bookmarks-subject-course-items {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(274px, 33.33%));
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 32px;
    row-gap: 32px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 650px) {
  .bookmarksHistory-main {
    min-height: 95vh;
    display: flex;
    justify-content: center;
  }

  .bookmarks-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  } 

  .bookmarks-subject-title-container {
    margin-bottom: 32px;
    display: flex;
  }

  .bookmarks-subject-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 1;
    margin-top: 16px;
  }

  .expand-btn {
    display: none;
  }

  .visible-on-small {
    display: block;
  }

  .hide-on-small {
    display: none;
  }

  .bookmarks-content-container {
    display: grid;
    grid-gap: 20px;
  }

  .bookmarks-subject-course-items{
    grid-row-gap: 0;
    row-gap: 0;
    grid-row-gap: 0;
  }
}

@media only screen and (max-width: 400px) {


  .bookmarksHistory-main {
    min-height: 95vh;
    display: block;
    justify-content: center;
  }

  .bookmarksHistory-main-container {
    width: 100%;
    margin: 0 0 0 16px;
  }

  .bookmarks-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }

  .bookmarks-subject-title-container {
    margin-bottom: 32px;
    display: flex;
  }

  .bookmarks-subject-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 1;
  }

  .expand-btn {
    display: none;
  }

  .visible-on-small {
    display: block;
  }

  .hide-on-small {
    display: none;
  }

  .bookmarks-content-container {
    display: grid;
    grid-template-columns: 360px;
    grid-gap: 20px;
  }

  .bookmarks-subject-course-items{
    grid-row-gap: 0;
    row-gap: 0;
    grid-row-gap: 0;
  }
}

.empty-search {
    height: 100vh;
    width: 100%;
    position: relative;
    color: rgba(var(--theme-text-primary))
}

.empty-search-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.empty-search-message img {
    margin-bottom: 16px;
}

@media screen and (max-width:480px) {
    .empty-search-message{
        left: 16px;
        transform: none;
    }
}
:root{
    --hover-tick-qnp: 1;
    --hover-complete-qnp: 0;
}

.tick-completed {
    opacity: var(--hover-tick-qnp);
    transition: opacity 200ms ease-in;
    position: absolute;
    padding: 8px;
}

.hover-container {
    position: relative;
    height: 40px;
    width: 40px;
}

.tick-completed {
    opacity: var(--hover-tick-qnp);
}

.more-completed {
    opacity: var(--hover-complete-qnp);
}

.tick-not-completed {
    opacity: 0;
    position: absolute;
    padding: 8px;
}

.more-completed {
    transition: opacity 200ms ease-in;
    opacity: var(--hover-complete-qnp);
    position: absolute;
    padding: 8px;
}

.more-not-completed {
    opacity: 1;
    position: absolute;
    padding: 8px;
    top: 0;
    left: 0;
}

.qnp-content-border-sm {
    scroll-snap-align: start;
}

.qnp-content-border-sm:hover{
    --hover-tick-qnp: 0;
    --hover-complete-qnp: 1;
}

.qnp-content-subject {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
}
.chapter-number {
    position: absolute;
    left: 17.86%;
    right: 17.86%;
    bottom: 28.57%;
    font-family: Fira Code;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -2.5px;
    color: #FFFFFF;
}

.qnp-logo {
    position: relative;
    height: 56px;
}

@media only screen and (min-width: 1199px) {
    .qnp-main-sm {
        display: none;
    }
    .qnp-title-row-sm {
        display: flex;
        padding: 16px 0px 16px 0;
    }
    .qnp-title-sm {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-lower-section-sm {
        display: grid;
        grid-template-columns: 100%;
    }
    .qnp-content-border-sm {
        display: flex;
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .qnp-content-border-sm:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }
    .qnp-img-sm {
        padding: 0;
    }
    .qnp-content-inside-sm {
        align-self: center;
        padding: 0 16px 0 16px;
    }
    .qnp-content-title-sm {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-content-pg-no-sm {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }
    .qnp-content-arrow-sm {
        /* margin: auto 8px auto 0; */
        height: -webkit-min-content;
        height: min-content;
        align-self: center;
    }
}

@media only screen and (max-width: 1199px) {
    .qnp-main-sm {
        display: none;
    }
    .qnp-title-row-sm {
        display: flex;
        padding: 16px 0px 16px 0;
    }
    .qnp-title-sm {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-lower-section-sm {
        display: grid;
        grid-template-columns: 100%;
    }
    .qnp-content-border-sm {
        display: flex;
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .qnp-content-border-sm:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }
    .qnp-img-sm {
        padding: 0;
    }
    .qnp-content-inside-sm {
        padding: 0 16px 0 16px;
        align-self: center;
    }
    .qnp-content-title-sm {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-content-pg-no-sm {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }
    .qnp-content-arrow-sm {
        /*         margin: auto 8px auto 0;
 */
        height: -webkit-min-content;
        height: min-content;
        align-self: center;
    }
}

@media only screen and (max-width: 767px) {
    .qnp-main-sm {
        display: block;
        height: 360px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(var(--theme-surface-main));
        margin-right: 16px;
    }
    .qnp-title-row-sm {
        display: flex;
        padding: 16px 0px 16px 0;
    }
    .qnp-title-sm {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-lower-section-sm {
        display: grid;
        grid-template-columns: 100%;
    }
    .qnp-content-border-sm {
        display: flex;
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .qnp-content-border-sm:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }
    .qnp-img-sm {
        padding: 0;
    }
    .qnp-content-inside-sm {
        padding: 0 16px 0 16px;
    }
    .qnp-content-title-sm {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }
    .qnp-content-pg-no-sm {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }
    .qnp-content-arrow-sm {
        /*         margin: auto 8px auto 0;
        
 */
        height: -webkit-min-content;
        height: min-content;
        align-self: center;
    }
}
@media (hover: hover) {
    .scroll-hide {
        -webkit-mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 10px, rgb(238, 92, 92) 30px);
                mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 10px, rgb(238, 92, 92) 30px);
        mask-size: 100% 20000px;
        mask-position: left bottom;
        -webkit-mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 10px, black 30px);
        -webkit-mask-size: 100% 20000px;
        -webkit-mask-position: left bottom;
        transition: mask-position 0.3s, -webkit-mask-position 0.3s;
    }

    .scroll-hide:hover {
        -webkit-mask-position: left top;
        mask-position: left top;
    }
}
.timelineContainer {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 30px 0px;
}

.timelineContainer > div {
  display: flex;
  justify-content: space-between;
}

.indicator {
  margin: auto;
  height: 56px;
  width: 56px;
  font-size: 34px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}


.green {
  background: #dcf2e6;
  color: #27ae60;
}

.grey {
  background: #ededed;
  color: rgba(0, 0, 0, 0.38);
}

.paymentTimeLineTextContainer {
  width: 94%;
  margin-left: -2px;
}

.verificationTimeLineTextContainer {
  width: 115%;
  margin-left: -10px;
}

.subText {
  margin-top: 10px;
  letter-spacing: 0.15px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(var(--theme-text-secondary));
  text-align: center;
}

.indicator .MuiSvgIcon-root {
  font-size: 35px;
}

@media screen and (max-width: 468px) {
  .timelineContainer {
    padding: 12px 0px;
  }

  .indicator {
    height: 35px;
    width: 35px;
    font-size: 21px;
  }

  .indicator .MuiSvgIcon-root {
    font-size: 21px;
  }

  .timelineDividerImage {
    margin: 0px 7px;
  }

  .paymentTimeLineTextContainer {
    width: 100%;
    margin-left: -8px;
  }

  .subText {
    font-size: 14px;
    line-height: 20px;
  }
}

.Mui-checked {
  color: #018786;
}

.MuiIconButton-colorSecondary:hover {
/*   background-color: rgba(0, 245, 245, 0.04) !important;
 */}

.recent-payment-status{
  margin-top: 8px;
}

.otpHeader {
  color: rgba(var(--theme-text-primary));
}

.otpDescription {
  color: rgba(var(--theme-text-secondary));
}

.otpInputContainer input {
  text-align: center;
  font-size: 34px;
  line-height: 36px;
  caret-color: #018786;
}

.otpInputContainerLogin input {
  text-align: center;
  font-size: 34px;
  line-height: 36px;
  caret-color: #018786;
}

@media screen and (max-width: 768px) {
  .OTPFormContainer {
    width: 100vw;
    max-width: 400px;
  }
}

.verificationContainer {
  margin: 28px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 400px;
}

.nesoLogo {
  text-align: center;
  padding: 20px 0;
}

.verificationBottomContainer {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 40px;
  border: 1px solid rgba(var(--theme-divider));
  width: 372px;
  height: auto;
  /*   max-height: 400px;
 */
  box-sizing: border-box;
}

.MuiInput-underline:after {
  border-bottom-color: #017374 !important;
}

.mobileVerificationTimelineContainer {
  margin-bottom: 18px;
}

@media screen and (max-width: 468px) {
  .verificationContainer {
    margin: 24px auto;
    padding: 0px 12px;
  }
  .nesoLogo {
    padding: 0px 0px 20px 0px;
  }
  .mobileVerificationTimelineContainer, .nesoLogo {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
  .verificationBottomContainer {
    width: 100%;
    margin-top: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    max-height: auto;
  }
}
.verifiedContainer {
  text-align: center;
  min-width: 312px;
}

.verifiedHeading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.verifiedSubHeading {
  margin-top: 16px;
  font-size: 16px;
  color: rgba(var(--theme-text-secondary));
}

.verifiedDoneAll {
  margin-top: 48px;
  height: 72px;
}

.verifiedDoneAll .MuiSvgIcon-root {
  height: 72px;
  width: 72px;
  color: #27ae60;
}

.verifiedNextButton {
  margin-top: 72px;
}

.verifiedNextButton .MuiButton-outlined {
  padding: 10px 16px;
}

.verifiedNextButton .MuiButton-outlined .MuiButton-label {
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 328px) {
  .verifiedContainer {
    width: 100%;
    min-width: 250px;
    max-width: 300px;
  }
}

@media screen and (max-width: 468px) {
  .verifiedContainer {
    min-height: 262px;
  }

  .verifiedDoneAll {
    margin-top: 32px;
  }

  .verifiedNextButton {
    margin-top: 32px;
  }
}

.SetupProfileContainer {
 /*  height: 433px; */
}

.SPHeading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.SPSubHeading {
  margin-top: 8px;
  font-size: 16px;
  color: rgba(var(--theme-text-secondary));
  text-align: center;
}

.cameraContainer {
  margin: auto;
  margin-top: 48px;
  position: relative;
  height: 114px;
  width: 114px !important;
  border-radius: 100%;
  border: 1px solid rgba(var(--theme-divider));
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameraContainer:hover {
  cursor: pointer;
}

.setupProfileButton > button {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.profileImage {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 114px;
  width: 114px;
}

.profileImage > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.userName {
  text-transform: capitalize;
  margin-top: 51px;
}

@media screen and (max-width: 468px) {
  .SetupProfileContainer {
    padding: 0px 32px;
  }
}



.makeStyles-root-171 .MuiTextField-root {
  display: flex;
  justify-content: center;
}

.toggleButton {
  color: var(--theme-secondary-main);
  font-weight: 500;
}

.toggleButton:hover {
  cursor: pointer;
}

.inputContainer {
  display: flex;
  justify-content: center;
  height: 56px;
}

.alertAcknowledgement {
  display: flex;
  justify-content: center;
}

.bLogo {
    display: flex;
}

.bTextGoogle {
    margin: 0px 18px;
    color: rgba(var(--theme-text-secondary));
}

.bTextFacebook {
    margin-left: 18px;
    color: rgba(var(--theme-text-secondary));
}

.bTextMobile {
    margin: 0px 18px;
    color: rgba(var(--theme-text-secondary));
}
.signupForm {
  box-sizing: border-box;
}

.SignupNumber {
  padding-bottom: 20px;
}


.subHeading {
  margin-top: 8px;
  font-size: 16px;
  color: #666666;
  font-weight: 400;
}

#recaptcha {
  display: flex;
  justify-content: center;
}

#recaptcha > div {
  width: 390px;
}

.NoMatchPage-Main-Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.NoMatchPage-Img {
    width: 100%;
    height: 244px;
    display: flex;
    justify-content: center;
    margin: 32px 0;
}

.NoMatchPage-Text {
    width: 480px;
    height: 97px;
    left: 480px;
    top: 497px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 48px;
    margin-left: 16px;
    margin-right: 16px;
}

.NoMatchPage-Button {
    display: flex;
    justify-content: center;
    margin-bottom: 20vh;
}

@media only screen and (max-width: 480px) {
    .NoMatchPage-Text {
        width: auto;
        height: auto;
        font-size: 24px;
        line-height: 32px;
    }
}
.formGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 22px;
  grid-column-gap: 22px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 468px) {
  .formGridContainer {
    display: flex;
    flex-direction: column;
    grid-row-gap: 17px;
  }
}

.paymentResultContainer {
  margin: auto;
  margin-top: 96px;
  width: 100%;
  max-width: 764px;
  grid-column-gap: 20px;
  display: flex;
}

.paymentResult {
  border: 1px solid rgba(var(--theme-divider));
  width: 470px;
}

.paymentResultHeader {
  border-bottom: 1px solid rgba(var(--theme-divider));
  height: 48px;
  padding: 16px 24px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(var(--theme-text-secondary));
  box-sizing: border-box;
}

.paymentResultSubscriptionPlanDetails {
  height: 88px;
  box-sizing: border-box;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(var(--theme-divider));
  display: flex;
  justify-content: space-between;
}

.paymentResultPlanInfo {
  margin-top: 8px;
}

.paymentResultPriceIcon {
  font-size: 24px;
}

.paymentResultPrice {
  height: 56px;
  display: flex;
  align-items: center;
  font-size: 45px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: rgba(var(--theme-text-primary));
}

.paymentResultBody {
  box-sizing: border-box;
  display: grid;
  padding: 24px 24px 36px;
  grid-row-gap: 20px;
  position: relative;
}

.GetReceiptButton {
  position: absolute;
  right: 24px;
  bottom: 36px;
}

.GetReceiptButton .MuiButton-root {
  color: var(--theme-secondary-main);
}

.paymentStatus {
  border: 1px solid rgba(var(--theme-divider));
  width: 274px;
  display: flex;
  flex-direction: column;
  height: inherit;
  min-height: 380px;
  box-sizing: border-box;
  padding: 36px 30px;
  position: relative;
}


@media screen and (max-width : 480px) {
  .paymentResultSubscriptionPlanDetails{
    height: auto;
    grid-gap: 8px;
    gap: 8px;
  }
  .paymentResultPrice{
    font-size: 32px;
  }
  .GetReceiptButton{
    bottom: 16px;
  }
  .paymentResultContainer {
    flex-wrap: wrap-reverse;
    grid-row-gap: 20px;
    height: auto;
    margin-top: 16px;
  }

  .paymentStatus {
    width: 100%;
  }
}
@media all {
    .page-break {
        display: none;
    }
}

.invoice-root{
    visibility: hidden;
    position: fixed;
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .with-border {
        border: 1px solid rgba(var(--theme-divider))
    }
}

@media print {

    p{
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    .no-print {
        visibility: hidden;
    }

    .gray{
        color: #707070;
    }

    .invoice-root{
        visibility: visible;
        position: relative;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 24mm;
}
.aboutContent {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  padding-top: 84px;
  padding-bottom: 193px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
}
.contentWrapper > h6 {
  padding-bottom: 32px;
}
.imgContent > img {
  padding-bottom: 40px;
}
.logoWrapper {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aboutLogoCluster {
  width: 216px;
  display: flex;
  justify-content: space-between;
}
h2 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.contentWrapper > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.logoWrapper > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.contentsec {
  margin-top: 32px !important;
  color: rgba(var(--theme-text-secondary));
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .aboutContent {
    margin: 0 5%;
    width: 90%;
  }
}
@media only screen and (max-width: 468px) {
  .aboutContent {
    height: auto;
    padding-bottom: 72px;
  }
  .contentWrapper:nth-child(2),
  .contentWrapper:nth-child(3),
  .logoWrapper {
    margin-top: 32px;
  }
}

.analyticsWrapper {
  border: 1px solid rgba(var(--theme-divider));
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.analyticsTitle {
  margin-top: 26px;
  display: flex;
  align-items: center;
}
.analyticsTitle > p {
  margin-left: 9.5px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.analyticsWrapper > p {
  margin-top: 32px;
  font-size: 34px;
  line-height: 36px;
  /* identical to box height, or 106% */

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.analytics {
  margin-top: 84px;
}
.analytics > .analyticsWrapper:nth-child(2),
.analyticsWrapper:nth-child(3) {
  margin-top: 32px;
}
@media only screen and (max-width: 468px) {
  .analytics {
    display: none !important;
  }
}
@media only screen and (max-width: 960px) {
  .analyticsWrapper > p {
    margin-top: 26px;
    font-size: 24px;
  }
  .analytics {
    margin-top: 17px;
  }
  .analytics {
    /* width: 109%; */
    margin: none;

    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
  }
  .analyticsWrapper {
    width: 31%;
    height: 136px;
  }
  .analytics > .analyticsWrapper:nth-child(2),
  .analyticsWrapper:nth-child(3) {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 960px) {
  .aboutUs {
    margin: 0;
  }
  #yo {
    width: 120%;
  }
}
.settings {
  width: 60%;
  margin: 0 20% 48px;
  height: 100vh;
}
.settingCluster {
  border: 1px solid rgba(var(--theme-divider));
  box-sizing: border-box;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
}
.settingContent {
  /* margin-top: 36px; */
  display: flex;
  max-width: 90%;
  margin: 36px 10%;
  justify-content: space-between;
}
.settingSwitch {
  width: 60%;
  position: relative;
}
/* .settingSwitchbtn {
  position: absolute;
  top: 0;
  right: 0;
} */
.settingSwitchCluster {
  display: flex;
  justify-content: space-between;
}
.settingDropdownCluster {
  display: flex;
  justify-content: space-between;
}
.settingThemeCluster {
  display: flex;
  justify-content: space-between;
}
.settingSubTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;
  margin-top: 7px;
  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingPara {
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin: auto 0 auto 0;

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingparatop {
  margin-top: 24px;
}
.settingsTitle {
  margin: 48px 0;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: 0.18px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.settingModePara {
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* 00. On Surface / Medium Emphasis */

  color: rgba(var(--theme-text-secondary));
}
/* #customized-select{
  display: none;
} */
.menuitem {
  color: rgba(var(--theme-text-primary));
}

.settings-select{
  height: 30px !important;
  width: 42px;
  overflow: hidden;
  padding: 4px 12px !important;
}

.settingDropdownCluster > .MuiInputBase-root > .MuiSelect-icon {
  display: none;
}
.settingDropdownCluster > .MuiInputBase-root > .MuiSelect-root {
  color: rgba(var(--theme-text-primary));
}
@media only screen and (max-width: 1554px) {
  .settingThemeCluster {
    flex-direction: column;
  }
  .settingCluster {
    height: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .settingTheme {
    margin-top: 20px;
  }
  .settings {
    width: 90%;
    margin: 0 5%;
  }
}

.notes-view-left {
    width: 37.08vw; 
    height: 100vh;
    padding-top: 80px;
}
#breadCrumb-button{
    margin: 0px -12px;
}

#notesview-section-header{
    margin-left: 47px;
    margin-top: 24px;
}

.notes-view-details {
    margin-left: 47px;
    margin-top: 48px;
    width: 46.73vw;
    max-width: 673px;
    border-bottom: 1px solid rgba(var(--theme-divider));
    padding-bottom: 32px;
}

.notes-view-details p {
    font-size: 34px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}

.notes-view-icons {
    max-width: 120px;
    display: flex;
    justify-content: space-between;
}

.notes-view-tablet {
    display: none;
}

.notes-view-pdf {
    padding: 36px;
}

.empty-notes-view {
    width: 49.58vw;
    height: 27.91vw;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.12);
}

.ppt-view-watch-video {
    border-bottom: 1px solid rgba(var(--theme-divider));
    margin-left: 48px;
    max-width: 682px;
    width: 47.36vw;
    padding-bottom: 64px;
}

.ppt-view-watch-video p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 24px;
}

.ppt-view-watch-video img {
    width: 47.43vw;
    height: 26.66vw;
    max-width: 683px;
    max-height: 384px;
}

.pdf-view-comment-section {
    margin: 32px 12.22vw 0 47px;
}


@media screen and (max-width: 1000px) {

    .notes-view-pdf {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .notes-view-tablet {
        display: block;
        padding: 0 4.16vw 18px 4.16vw;
        border-bottom: 1px solid rgba(var(--theme-divider));
    }

    #notes-section-scrolled {
        position: fixed;
        top: 64px;
        left: 0;
        right: 0;
    }

    #notesview-section-header {
        margin: 24px 0;
    }

    .ppt-pages {
        margin: 0 70px 0 0;
    }

    .notes-view-tablet-details {
        width: 100%;
        justify-content: space-between;
        display: flex;
        background-color: white;;
    }

    .notes-icons {
        width: 72px;
        display: flex;
        justify-content: space-between;
    }

    .notes-view-header {
        display: none;
    }

    #first-span {
        max-width: none;
    }

    .empty-notes-view {
        width: 91.66vw;
        height: 52.34vw;  
    }

    .ppt-view-watch-video {
        margin-left: 4.16vw;
        width: 91.66vw;
        height: 52.34vw;
    }

    .ppt-view-watch-video img {
        width: 100%;
        height: 100%;
    }

    .pdf-view-comment-section {
        margin: 32px 0 0 4.16vw;
    }

    .comments {
        padding-left: 0;
    }


}

.reply-mention {
  color: rgba(var(--theme-text-secondary));
  padding-right: 2px;
}

.reply-section {
  margin-top: 23px;
}

.commenter-name {
  color: rgba(var(--theme-text-primary));
}

.comment-item {
  display: grid;
}

.delete-btn {
  margin-top: -12px;
}

.commenter-badge {
  width: 18px;
  position: relative;
  height: 18px;
}

#replies-open.main-content {
  border-bottom: none;
}

.reply-close-button {
  margin-right: 12px;
  cursor: pointer;
}

.reply-btn {
  min-width: auto;
  margin-left: -8px;
}

@media only screen and (min-width: 1200px) {
  .comment-item {
    margin-top: 18px;
    /*background-color: red;*/
  }

  .main-comment {
    display: flex;
    grid-template-columns: 40px auto;
    grid-column-gap: 16px;
  }

  .input-icons {
    position: relative;
  }

  .main-content {
    flex-grow: 1;
    border-bottom: 1px solid rgba(var(--theme-divider));
    padding-bottom: 5px;
  }

  p {
    margin: 0;
  }

  .commenter-name {
    margin: 0 8px 0 0;
  }

  .commenter {
    display: flex;
    align-items: center;
  }

  .grow {
    flex-grow: 1;
  }

  .delete-icon {
    color: rgba(var(--theme-text-secondary));
  }

  .time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
    margin-bottom: 4px;
  }

  .comment-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
 

  .comment-item {
    margin-top: 18px;
    width: 98%;
  }

  .input-icons {
    position: relative;
  }

  .main-comment {
    display: flex;
    grid-template-columns: 40px auto;
    grid-column-gap: 10px;
  }

  .main-content {
    flex-grow: 1;
    border-bottom: 1px solid rgba(var(--theme-divider));
    padding-bottom: 5px;
  }

  p {
    margin: 0;
  }

  .commenter-name {
    margin: 0 8px 0 0;
  }

  .commenter {
    display: flex;
    align-items: center;
  }

  .grow {
    flex-grow: 1;
  }

  .delete-icon {
    color: rgba(var(--theme-text-secondary));
  }

  .time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
    margin-bottom: 4px;
  }

  .comment-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 700px) {

  .reply-section {
    margin-top: 10px;
  }

  .comment-item {
    margin-top: 18px;
    width: 98%;
  }

  .input-icons img {
    position: absolute;
    right: 25px;
    top: 9px;
    width: 18px;
    cursor: pointer;
  }

  .input-icons {
    position: relative;
  }

  .main-comment {
    display: flex;
    grid-template-columns: 40px auto;
    grid-column-gap: 10px;
  }

  .main-content {
    flex-grow: 1;
    border-bottom: 1px solid rgba(var(--theme-divider));
    padding-bottom: 5px;
  }

  p {
    margin: 0;
  }

  .commenter-name {
    margin: 0 8px 0 0;
  }

  .commenter {
    display: flex;
    align-items: center;
  }

  .grow {
    flex-grow: 1;
  }

  .delete-icon {
    color: rgba(var(--theme-text-secondary));
  }

  .time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
    margin-bottom: 4px;
  }

  .comment-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 10px;
  }
}
.comment-button-text {
    color: var(--theme-secondary-main)
}

.comments-root {
    margin-top: 0px;
}

.comment-handle-panel {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px
}

.reply-tag {
    padding: 2px 8px;
    margin-right: 4px;
    border-radius: 2px;
    white-space: nowrap;
    max-width: 260px;
    font-size: 14px;
    color: rgba(var(--theme-text-secondary));
    background-color: rgba(var(--theme-divider));
}

@media only screen and (min-width: 1200px) {
    .comments {
        min-height: 500px;
    }

    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        align-items: center;
        grid-template-columns: 40px auto;
        grid-column-gap: 16px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 100%;
        height: 36px;
        background: rgba(var(--theme-surface-main));
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 1200px) {
    .comments-root {
        margin-top: 32px;
    }

    .comment-handle-panel {
        margin-right: 32px
    }

    .comments {
        padding-left: 10px;
    }

    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        grid-template-columns: 40px auto;
        grid-column-gap: 10px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 95%;
        height: 36px;
        background: rgba(33, 33, 33, 0.08);
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 468px) {
    .comments {
        padding-left: 10px;
    }

    .comments-root {
        margin-top: 16px;
    }


    .no-of-comments {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-secondary));
        margin-bottom: 26px;
    }

    .main-writeComments {
        display: grid;
        grid-template-columns: 40px auto;
        grid-column-gap: 10px;
        margin-bottom: 18px;
    }

    .writeComments-input {
        width: 95%;
        height: 36px;
        background: rgba(33, 33, 33, 0.08);
        border-radius: 4px;
        padding-left: 10px;
        border: none;
    }

    .writeComments-input:focus {
        outline: none !important;
    }

    ::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(var(--theme-text-secondary));
        margin: 10px 0px;
    }

    .list-of-comments {
        margin-bottom: 18px;
    }

    .load-btn {
        text-align: center;
        margin-bottom: 48px;
    }

    .comment-handle-panel {
        margin-right: 16px
    }
}
.notes-view-section {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    position: relative;
}

.notes-view-left-section {
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
}

.notes-view-right-section {
    position: relative;
    left: 37.08vw;
    border-left: 1px solid rgba(var(--theme-divider)); 
}

@media screen and (max-width: 1000px) {
    .notes-view-left-section {
        display: none;
    }

    .notes-view-right-section {
        width: 100%;
        border-left: none;
        left: 0;
    }
}



:root {
  --theme-primary-main : #BF232Dff;/* primary */
  --theme-secondary-main : #018786ff;/* secondary */

  --theme-background-default: 255,255,255,1; /* background default */
  --theme-background-paper : 255,255,255,1 ;/* background paper */
  --theme-background-surface : rgba(255,255,255,1);

  --theme-surface: #F8F9FB;
  --theme-contrast-color : #000000;

  --theme-text-primary: 0,0,0,0.87 ; /* text primary rgba(255,255,255,0.87) */
  --theme-text-secondary: 0,0,0,0.6;/* text secondary rgba(255,255,255,0.6) */
  --theme-text-disabled: 0,0,0,0.38 ;/* text disabled rgba(255,255,255,0.38) */
  --theme-text-button-filled : 255,255,255,1;
  --theme-container-background : #f8f9fbff; /* custom color */
  --theme-divider: 0,0,0,0.12; /* divider */
  --theme-background-paid-chapter : rgba(248, 249, 251, 1);
  --theme-surface-main : 33,33,33,0.08;
  --theme-cw-gradient : #FFFFFF;
  --theme-cw-gradient-rgba : 255,255,255,0;
  --theme-overlay-border : 0,0,0,0.12;

  /*scrollbar*/
  /*background same as default background*/
  --theme-scrollbar-thumb: #dbdbdb;

  /* syntax-highlighting */
  --syntax-keyword: #AA0D91;
  --syntax-string: #C41A16;
  --syntax-comment: #007400;

  /* Others */
  --theme-tag-background: rgba(242, 153, 74, 0.16);

  /*defaults*/
  background-color:  rgba(var(--theme-background-default));
}
  
 [dark-theme="dark"] {
    --theme-primary-main : #D18A8Eff;/* primary */
    --theme-secondary-main : #03DAC5ff;/* secondary */

    --theme-background-default: 18,18,18,1; /* background default */
    --theme-background-paper : 18,18,18,1 ;/* background paper */
    --theme-background-surface : linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;

    --theme-surface: #1E1E1E;
    --theme-contrast-color : #FFFFFF;


    --theme-text-primary: 255,255,255,0.87 ; /* text primary rgba(255,255,255,0.87) */
    --theme-text-secondary: 255,255,255,0.6;/* text secondary rgba(255,255,255,0.6) */
    --theme-text-disabled: 255,255,255,0.38 ;/* text disabled rgba(255,255,255,0.38) */
    --theme-text-button-filled : 0,0,0,1;

    --theme-container-background : #000000; /* custom color */
    --theme-divider: 255,255,255,0.12; /* divider */
    --theme-surface-main : 255,255,255,0.12;
    /* --theme-background-paid-chapter : linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212; */
    --theme-background-paid-chapter: rgba(255, 255, 255, 0.05);
    --theme-cw-gradient : #1B1B1B;
    --theme-cw-gradient-rgba : 0,0,0,0;
    --theme-overlay-border : 255, 255, 255, 0.12;

    --theme-scrollbar-thumb: #3a3a3a;

    /* syntax-highlighting */
    --syntax-keyword: #FF9EF0;
    --syntax-string: #FF9390;
    --syntax-comment: #89FF89;

    /* Others */
    --theme-tag-background: rgba(255, 144, 137, 0.31);
    
    /*defaults*/
    background-color:  rgba(var(--theme-background-default));
  }
.dialogFormContainer::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.dialogFormContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* custom input field colour */
.MuiFormLabel-root.Mui-focused {
  color: #018786;
}

.MuiAlert-standardError {
  width: 375px;
}

.dialogFormContainer > div {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.MuiDialog-paperScrollPaper {
  border-radius: 0px;
}



/* body::-webkit-scrollbar {
  width: 10px;
} */

/* Track */

/* body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
} */

/* Handle */

/* body::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
} */

/* Handle on hover */

/* body::-webkit-scrollbar-thumb:hover {
  background: lightgray;
} */

.container {
  /*box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);*/
  /* margin-left: clamp(0px, 16px, 5%);
    margin-right : clamp(0px, 6px, 5%); */
  position: relative;
  padding-top: 1px;
  margin-top: -1px;
  z-index: 5;
  background-color: rgba(var(--theme-background-default));
}

#close.container {
  margin-top: 0px;
}

#open.container {
  margin-top: 342px;
}

.root {
  padding-top: 1px;
  margin-top: -1px;
  position: relative;
}

.latest-section {
  transition: max-height 300ms ease-in;
  background-color: var(--theme-container-background);
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
  max-height: 0px;
}

#open.latest-section {
  max-height: 700px;
}

/* * {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
} */

@media only screen and (min-width: 1200px) {
  .LeavesAd-Home {
    background-color: lightgray;
    width: 768px;
    height: 90px;
    margin: 32px auto 48px auto;
  }
  /* .container {
        margin-left: clamp(0px, 126px, 5%);
        margin-right : clamp(0px, 116px, 5%);
    } */
  .home-main {
    display: flex;
    justify-content: stretch;
  }
  /*.faculty-home {*/
  /*    display: flex;*/
  /*    justify-content: center;*/
  /*}*/
  .Courses {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 1200px) {
  .LeavesAd-Home {
    background-color: lightgray;
    width: 468px;
    height: 60px;
    margin: 32px auto;
  }
  /*  .container{
        margin-left: clamp(0px, 16px, 5%);
        margin-right : clamp(0px, 6px, 5%);
    }
 */
  /*.Courses {*/
  /*  margin-top: 60px;*/
  /*}*/
}

@media only screen and (max-width: 468px) {
  .LeavesAd-Home {
    background-color: lightgray;
    width: 300px;
    height: 50px;
    margin: 32px auto;
  }
  #open.container {
    margin-top: 290px;
  }
  /* .MuiContainer-root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  } */
  /*
  .container {
      margin: 0;
  } */
  .Courses {
    margin-top: 24px;

  }
}

/*.courses-flex {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.courseTitle-flex {*/
/*    display: flex;*/
/*    justify-content:  center;*/
/*}*/

.Courses-Header{
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}

.Course-Title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}
@media only screen and (min-width: 1200px) {
  .grid-container {
    justify-content: stretch;
    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-column-gap: 20px;
  }
  .grid-item {}
}

@media only screen and (max-width: 1200px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .grid-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    padding-top: 32px;
    grid-column-gap: 0px;
    scrollbar-width: none;
    /* for firefox */
    -ms-overflow-style: none;
    /* for IE */
  }
  .grid-item {
    margin-right: 20px;
  }
  .grid-container::-webkit-scrollbar {
    display: none;
  }
  /* ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  } */
}
.Loader-Container-course {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}


.placeholder-course-card{
  border-radius: 4px;
}

@media only screen and (min-width: 1200px) {
  .Loader-Container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
  }

  .cw-loader {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 36px;
  }

  .title-loader {
    margin: 52px 0 32px 0;
  }

  .rv-loader {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
  }

  .title-loader1 {
    display: none;
  }

  .st-loader {
    display: flex;
    grid-gap: 40px;
    gap: 40px;
  }

  .main-loader {
    margin: 40px 0 0 0;
  }

  .loader-box-main {
    margin: 32px 0 40px 0;
  }


  .main-loader2 {
    display: none;
  }

  .size-on-md-lg {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .Loader-Container {
    margin-top: 480px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  }

  .main-loader2 {
    display: none;
  }

  .size-on-md-lg {
    display: block;
  }

  .cw-loader {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 36px;
  }

  .title-loader {
    margin: 52px 0 32px 0;
  }

  .title-loader1 {
    display: none;
  }

  .rv-loader {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;
    padding-bottom: 20px;
  }

  .st-loader {
    display: flex;
    grid-gap: 40px;
    gap: 40px;
  }

  .main-loader {
    margin: 50px 0 0 10px;
  }

  .hide-md {
    display: none;
  }

  .loader-box-main {
    margin: 32px 0 20px 0;
  }

  .size-on-md-lg {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .main-loader2 {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -20px;
  }

  .main-loader {
    display: none;
  }

}

.modal-main {
  background-color: rgba(var(--theme-background-default)) !important;
}

.img-psd-ill {
  max-width: 592px;
  height: 358px;
}

.psd-illu-div {
  padding-right: 90px;
}

.outer-psd {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.main-psd {
  width: 100%;
  height: 360px;
  border: 1px solid rgba(var(--theme-divider));
  box-sizing: border-box;
}

.psd-flex {
  display: flex;
}

.align-left {
  padding-left: 82px;
}

.neso-title-psd {
  padding-top: 71px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-primary))
}

.neso-desc-psd {
  padding-top: 16px;
  width: 274px;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-primary))
}

.get-it-on-ps {
  padding-top: 50px;
  padding-bottom: 10px;
  height: 38px;
}

.scan-btn {
  margin-top: 8px;
  margin-left: -8px;
  color: var(--theme-secondary-main)
}

.scan-psd {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--theme-secondary-main);
}

.flex-grow-psd {
  flex-grow: 1;
}

.psd-illu-div-xs {
  display: none;
}

.psd-illu-div-sm {
  display: none;
}

.main-modal {
  position: absolute;
  width: 900px;
  height: 560px;
  background-color: rgba(var(--theme-background-default));
  box-shadow: none;
  padding: 16px 32px 24px;
  box-sizing: border-box;
}

.main-modal:focus {
  outline: none;
}

.cross-div {
  display: flex;
}

.modal-split {
  display: flex;
}

.left-split-modal {
  padding-left: 94px;
}

.scan-img {
  width: 160px;
  height: 160px;
  padding-top: 66px;
}

.growing-modal {
  flex-grow: 1;
}

.scan-me-text {
  padding-top: 32px;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  color: rgba(var(--theme-text-primary));
}

.scan-me-desc {
  width: 262px;
  height: 48px;
  padding-top: 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(var(--theme-text-secondary));
}

.phone-img-modal {
  width: 266px;
  height: 474px;
  padding: 30px 94px 0 0;
}

@media only screen and (max-width: 960px) {
 
  .outer-psd {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .main-psd {
    width: 100%;
    height: 300px;
    border: 1px solid rgba(var(--theme-divider));
    box-sizing: border-box;
  }
  .psd-flex {
    display: flex;
  }
  .align-left {
    padding-left: 48px;
  }
  .neso-title-psd {
    padding-top: 43px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary))
  }
  .neso-desc-psd {
    padding-top: 16px;
    width: 211px;
    height: 72px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary))
  }
  .get-it-on-ps {
    padding-top: 26px;
  }
  .scan-btn {
    margin-left: -8px;
    color: var(--theme-secondary-main)
  }
  .scan-psd {
    padding-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--theme-secondary-main);
  }
  .flex-grow-psd {
    flex-grow: 1;
  }
  .img-psd-ill {
    height: 298px;
  }

  .psd-illu-div{
    display: none;
  }

  .psd-illu-div-xs{
    display: none;
  }

  .psd-illu-div-sm {
    display: block;
    padding-right: 43px;
  }
  .main-modal {
    position: absolute;
    width: 704px;
    height: 560px;
    background-color: rgba(var(--theme-background-default));
    box-shadow: none;
    padding: 16px 32px 24px;
    box-sizing: border-box;
  }
  .main-modal:focus {
    outline: none;
  }
  .cross-div {
    display: flex;
  }
  .modal-split {
    display: flex;
  }
  .left-split-modal {
    padding-left: 28px;
  }
  .scan-img {
    width: 160px;
    height: 160px;
    padding-top: 66px;
  }
  .growing-modal {
    flex-grow: 1;
  }
  .scan-me-text {
    padding-top: 32px;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    color: rgba(var(--theme-text-primary))
  }
  .scan-me-desc {
    width: 262px;
    height: 48px;
    padding-top: 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-secondary))
  }
  .phone-img-modal {
    width: 266px;
    height: 474px;
    padding: 30px 28px 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .outer-psd {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .main-psd {
    height: 460px;
    border: 1px solid rgba(var(--theme-divider));
    box-sizing: border-box;
  }
  .psd-flex {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: center;
  }
  .align-left {
    padding-left: 0;
  }
  .neso-title-psd {
    padding-top: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary))
  }
  .neso-desc-psd {
    padding-top: 8px;
    width: 255px;
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary))
  }
  .get-it-on-ps {
    padding-top: 20px;
  }
  .scan-btn {
    margin-left: 0;
    color: var(--theme-secondary-main)
  }
  .scan-psd {
    padding-top: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--theme-secondary-main);
    justify-content: center;
  }
  .flex-grow-psd {
    flex-grow: 0;
  }
  .sm-max {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .psd-illu-div {
    display: none;
  }
  .psd-illu-div-sm{
    display: none;
  }
  .img-psd-ill {
    height: 228px;
  }
  .psd-illu-div-xs {
    display: block;
    padding-top: 22px;
  }
  .main-modal {
    position: absolute;
    width: 312px;
    height: 311px;
    background-color: rgba(var(--theme-background-default));
    box-shadow: none;
    padding: 16px 32px 24px;
    box-sizing: border-box;
  }
  .main-modal:focus {
    outline: none;
  }
  .cross-div {
    display: flex;
  }
  .modal-split {
    display: flex;
    justify-content: center;
  }
  .left-split-modal {
    padding-left: 0;
  }
  .cross-img {
    width: 24px;
    height: 24px;
  }
  .scan-img-div {
    display: flex;
    justify-content: center;
  }
  .scan-img {
    width: 100px;
    height: 100px;
    padding-top: 1px;
  }
  .growing-modal {
    flex-grow: 0;
  }
  .scan-me-text {
    padding-top: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary))
  }
  .scan-me-desc {
    width: 213px;
    height: 72px;
    padding-top: 16px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-secondary))
  }
  .phone-img-modal {
    display: none;
  }
}
.content-wrapper {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

@media only screen and (min-width: 1200px) {
  .faculty-hide-only-in-large {
    display: none;
  }
  .faculty-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    z-index: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .content-wrapper::-webkit-scrollbar {
    display: none;
  }
  .content-wrapper div {
    padding: 4px;
    color: white;
  }
  .magic-wrapper {
    height: 35px;
    overflow-y: hidden;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .faculty-only-in-large {
    display: none;
  }
  .faculty-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
  .content-wrapper div {
    padding: 3px 5px 3px 5px;
    color: white;
  }
  .magic-wrapper {
    height: 35px;
    overflow-y: hidden;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .faculty-only-in-large {
    display: none;
  }
  .faculty-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .content-wrapper div {
    padding: 0 7px 0 7px;
    color: white;
  }
  .magic-wrapper {
    height: 35px;
    overflow-y: hidden;
    width: 100%;
  }

  .content-wrapper::-webkit-scrollbar{
    display: none;
  }

}
.gradient-label{
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.facultyCard-main {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: top ease 0.5s;
    position: relative;
    top: 0;
}

.facultyCard-main:hover {
    border-radius: 4px;
    top: -10px;
}

.faculty-img {
    padding: 12px;
    margin-bottom: 1px;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: 100%;
}

.faculty-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    grid-column-gap: 4px;
    column-gap: 4px;
    letter-spacing: 0.15px;
    margin: 4px 0px 9px 0px ;
    color:rgba(var(--theme-text-primary));
    flex: 1 0 100%;
}


.dept {
    padding: 4px 6px;
    border-radius: 4px;
    background: rgba(255, 122, 0, 0.16);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #F2994A;       
}

@media only screen and (max-width: 468px) {

    .facultyCard-main{
        padding-bottom: 0px;
    }

    .facultyCard-main:hover {
        top: 0px;
    }

    .faculty-img{
        padding: 0px;
    }

    .faculty-name {
        margin: 8px 0px 8px 0px;
        font-size: 14px;
        line-height: 20px;
        flex-direction: column;
        align-items: center;
    }

}


.Primary-filled {
    width: 93px;
    height: 36px;
    background:var(--theme-primary-main);
    border-radius: 4px;
    padding: 10px 16px;
    color: var(--theme-cw-gradient);
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    box-shadow: none;
}

.Primary-filled:hover {
    width: 93px;
    height: 36px;
    background: var(--theme-primary-main);
    border-radius: 4px;
    padding: 10px 16px;
    color: var(--theme-cw-gradient);
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    box-shadow: none;
}



@media only screen and (min-width: 1200px) {
  .flex-container-nesofuel {
    display: flex;
    justify-content: stretch;
  }

  .nesoFuel {
    border: 1px solid rgba(var(--theme-divider));
    margin-bottom: 48px;
    height: 359px;
    width: 100%;
  }

  .art-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .left-art-container {
    width: 411px;
    margin: 48px auto 64px 82px;
  }

  .fuelImg {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }

  .nesoFuel-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 20px;
  }

  .nesoFuel-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
  }

  .right-art-container {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/neso-c53c4.appspot.com/o/ImageResource%2FWebsiteResource%2FNesoGetFuelSideImg.webp?alt=media&token=82adc469-e913-4b99-af53-ec4ef9d3990d");
    background-size: 517px;
    background-repeat: no-repeat;
     height: 360px;
    position: relative;
    margin-right: -150px;
    right: 150px;
  }

  .artImg {
    float: right;
  }
}

@media only screen and (max-width: 1200px) {
  .flex-container-nesofuel {
    display: flex;
    justify-content: center;
  }

  .nesoFuel {
    border: 1px solid rgba(var(--theme-divider));
    margin-bottom: 48px;
    height: 359px;
    width: 100%;
  }

  .art-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .left-art-container {
    width: 295px;
    margin: 48px auto 64px 48px;
  }

  .fuelImg {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }
  /* 
  .only-large {
    display: none;
  } */

  .nesoFuel-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 20px;
  }

  .nesoFuel-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
  }

  .right-art-container {
    background-image: url("http://rct.nesoacademy.org/assets/images/fuelBgImages/Art.webp");
    background-size: 517px;
    background-repeat: no-repeat;
    position: relative;
    right: 150px;
    margin-right: -150px;
    /* height: 360px; */
    /* right: 100px; */
  }

  .artImg {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .flex-container-nesofuel {
    justify-content: center;
    width: 100%;
    background-color: rgba(var(--theme-container-background));
  }

  .only-large {
    display: none;
  }

  .nesoFuel {
    border: none;
    margin-bottom: 48px;
    max-height: 328px;
    max-width: 360px;
  }

  .art-container {
    padding: 0px 12px;
    display: grid;
    grid-template-columns: 100%;
    height: 100%;
    background-color: rgba(var(--theme-container-background));
  }

  .left-art-container {
    /* padding-left: 16px; */
    width: 100%;
    margin: auto auto auto 0px;
  }

  .left-art-container img {
    width: 36px;
  }

  .fuelImg {
    width: 36px;
    height: 36px;
    margin-bottom: 16px;
  }

  .nesoFuel-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 12px;
  }

  .nesoFuel-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-secondary));
    margin-bottom: 32px;
  }

  .right-art-container {
    display: none;
  }

  .artImg {
    width: 400px;
  }
}

.continueVideo-background {
  height: 328px;
  border-radius: 4px;
  padding-top: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}
.continueVideo-flex {
  display: inline-block;
}
.welcome-user-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(var(--theme-divider));
  border-radius: 4px;
  flex-grow: 1;
  height: 360px;
}

.welcome-user-inner-container {
  margin-left: 56px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.welcome-user-title {
  margin-bottom: 16px;
  color: rgba(var(--theme-text-primary));
}

.welcome-user-description {
  margin-bottom: 48px;
  color: rgba(var(--theme-text-secondary));
}

.welcome-user-button {
  background: rgba(1, 135, 134, 0.12);
  border-radius: 4px;
}

.welcome-user-button:hover {
  background: rgba(1, 135, 134, 0.12);
  border-radius: 4px;
}

@media only screen and (min-width: 1000px) {
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    padding: 24px 0 0 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: white;
    /* rgba(var(--theme-text-primary)) */
  }
  .continueVideo-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .continueVideo {
    width: 100%;
    height: 360px;
    border-radius: 4px;
    background-position-y: center;
    background-size: cover;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .chapter-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #03DAC5;
    padding-left: 32px;
  }
  .chapter-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: white;
    /*  rgba(var(--theme-text-primary)) */
    ;
    padding-top: 12px;
    width: 360px;
    padding-left: 32px;
  }
  .grow-description {
    flex-grow: 1;
  }
  .continueVideo-flex {
    width: 100%;
    margin-bottom: 32px;
  }
  .continueVideo-description {
    display: flex;
    justify-content: stretch;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
  }
  .remaining-time {
    background-color: #FFFFFF  /* rgba(var(--theme-background-default)) */;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.6) /* rgba(var(--theme-text-secondary)) */;
  }
  .play-icon {
    padding-right: 24px;
  }
  .small-screen-flex {
    display: none;
  }
  .box-ad {
    width: 328px;
    height: 360px;
    background-color: lightgray;
  }
}

@media only screen and (max-width: 1000px) {
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    padding: 24px 0 0 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: white;
  }
  /* .setting-width {
    width: 100%;
  } */
  .continueVideo-container {
    /* display: flex; */
    justify-content: space-between;
  }
  .continueVideo-description {
    display: flex;
    justify-content: stretch;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
  }
  .continueVideo {
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position-y: center;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .chapter-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #03DAC5;
    padding-left: 32px;
  }
  .chapter-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    padding-top: 12px;
    width: 270px;
    padding-left: 32px;
    color: white;
  }
  .grow-description {
    flex-grow: 1;
  }
  .continueVideo-flex {
    width: 100%;
    margin-bottom: 32px;
  }
  .remaining-time {
    background-color: #FFFFFF/* rgba(var(--theme-background-default)) */
    ;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.6)/* rgba(var(--theme-text-secondary)) */
    ;
  }
  .play-icon {
    padding-right: 24px;
  }
  .small-screen-flex {
    display: none;
  }
  .box-ad {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .continueVideo {
    height: 268px;
  }
  .continueVideo-background {
    height: 236px;
  }
  .chapter-title {
  }
}

@media only screen and (max-width: 600px) {
  .continueVideo-background {
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .where-you-left-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin: 32px 0;
  }
  .heading-cv {
    display: none;
  }
  .continueVideo-container {
    display: block;
    width: 100%;
  }
  .continueVideo-description {
    margin: 0px;
  }
  .continueVideo {
    width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid rgba(var(--theme-divider));
    cursor: pointer;
  }
  .small-screen-remain-time {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(var(--theme-text-secondary));
    margin-top: 12px;
    flex: 0 1 100%;
  }
  .small-screen-remain-title {
    max-width: 284px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(var(--theme-text-primary));
  }
  .flex-grow-small-screen {
    flex-grow: 1;
  }
  .small-screen-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .small-screen-play-icon {
    height: 36px;
    width: 36px;
    align-self: center;
  }
  .chapter-title {
    display: none;
  }
  .continueVideo-background {
    height: auto;
    padding-bottom: 0;
  }
  .chapter-description {
    display: none;
  }
  .remaining-time {
    display: none;
  }
  .play-icon {
    display: none;
  }
  .box-ad {
    display: none;
  }
  .welcome-user-container {
    height: auto;
  }
  .welcome-user-inner-container{
    margin-left: 32px;
  }
}
.qnn-lower-section::-webkit-scrollbar {
  display: none;
}

.qnn-lower-section {
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: 100% 100%;
  overflow-x: scroll;
}

.qnn-content-border {
  scroll-snap-align: start;
}

@media only screen and (min-width: 1000px) {
  .qnn-main {
    width: 372px;
    border: 1px solid rgba(var(--theme-divider));
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 20px;
  }
  .qnn-title-row {
    display: flex;
    padding: 16px 18px 16px 18px;
    border-bottom: 1px solid rgba(var(--theme-divider));
  }
  .qnn-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-title-grow {
    flex-grow: 1;
  }
  .qnn-lower-section {
    padding: 8px 0px 8px 0px;
    width: 356px;
    margin: 0 8px 0 8px;
  }
  .qnn-content-border {
    display: flex;
    cursor: pointer;
    padding: 8px;
  }
  .qnn-content-border:hover {
    background-color: rgba(var(--theme-surface-main));
    border-radius: 4px;
  }
  .qnn-img {
    width: 56px;
    height: 56px;
  }
  .qnn-content-inside {
    padding: 0 16px 0 16px;
  }
  .qnn-content-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-content-pg-no {
    padding-top: 2px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
  }
  .qnn-content-arrow {
    margin: auto 0 auto 0;
  }
  .qnn-arrow-left {
    cursor: pointer;
    margin-right: 40px;
  }
  .qnn-arrow-right {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1000px) {
  .qnn-arrow-left {
    display: none;
  }
  .qnn-arrow-right {
    display: none;
  }
  .qnn-main {
    display: block;
    width: 100%;
    height: 360px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(var(--theme-divider));
  }
  .qnn-title-row {
    margin-top: 24px;
    display: flex;
    padding: 16px 20px 16px 0px;
  }
  .qnn-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-title-grow {
    flex-grow: 1;
  }
  .qnn-lower-section {
    padding: 8px 0 0 0;
  }
  .qnn-content-border {
    display: flex;
    width: 100%;
    height: 72px;
    cursor: pointer;
  }
  .qnn-content-border:hover {
    background-color: rgba(var(--theme-surface-main));
    border-radius: 4px;
  }
  .qnn-img {
    padding: 12px 0 12px 14px;
  }
  .qnn-content-inside {
    padding: 15px 0 0 22px;
  }
  .qnn-content-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-content-pg-no {
    padding-top: 2px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
  }
  .qnn-content-arrow {
    padding: 24px 8px 0 0;
  }
}

@media only screen and (max-width: 648px) {
  .qnn-arrow-left {
    display: none;
  }
  .qnn-arrow-right {
    display: none;
  }
  .qnn-main {
    display: block;
    width: 100%;
    height: 360px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(var(--theme-divider));
  }
  .qnn-title-row {
    display: flex;
    padding: 16px 20px 16px 0px;
  }
  .qnn-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-title-grow {
    flex-grow: 1;
  }
  .qnn-lower-section {
    padding: 8px 0 0 0;
  }
  .qnn-content-border {
    display: flex;
    width: 100%;
    height: 72px;
    cursor: pointer;
  }
  .qnn-content-border:hover {
    background-color: rgba(var(--theme-surface-main));
    border-radius: 4px;
  }
  .qnn-img {
    padding: 12px 0 12px 14px;
  }
  .qnn-content-inside {
    padding: 15px 0 0 22px;
  }
  .qnn-content-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
  }
  .qnn-content-pg-no {
    padding-top: 2px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(var(--theme-text-secondary));
  }
  .qnn-content-arrow {
    padding: 24px 8px 0 0;
  }
}


@media only screen and (min-width: 1199px) {
    .qnn-main-md {
        display: none;
    }

    .qnn-title-row-md {
        display: flex;
        padding: 16px 20px 16px 18px;
    }

    .qnn-title-md {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-title-grow-md {
        flex-grow: 1;
    }

    .qnn-lower-section-md {
        padding: 8px 8px 0 8px;
        display: grid;
        grid-template-columns: auto auto;
    }

    .qnn-content-border-md {
        display: flex;
        width: 100%;
        height: 72px;
        cursor: pointer;
    }

    .qnn-content-border-md:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }

    .qnn-img-md {
        padding: 12px 0 12px 14px;
    }

    .qnn-content-inside-md {
        padding: 15px 0 0 22px;
    }

    .qnn-content-title-md {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-content-pg-no-md {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }

    .qnn-content-arrow-md {
        padding: 24px 8px 0 0;
    }
}

@media only screen and (max-width: 1199px) {
    .qnn-main-md {
        display: block;
        width: 100%;
        height: 360px;
        padding-bottom: 32px;
        border-bottom: 1px solid  rgba(var(--theme-divider));
    }

    .qnn-title-row-md {
        display: flex;
        padding: 16px 20px 16px 18px;
    }

    .qnn-title-md {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-title-grow-md {
        flex-grow: 1;
    }

    .qnn-lower-section-md {
        padding: 8px 8px 0 8px;
        display: grid;
        grid-template-columns: auto auto;
    }

    .qnn-content-border-md {
        display: flex;
        width: 100%;
        height: 72px;
        cursor: pointer;
    }

    .qnn-content-border-md:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }

    .qnn-img-md {
        padding: 12px 0 12px 14px;
    }

    .qnn-content-inside-md {
        padding: 15px 0 0 22px;
    }

    .qnn-content-title-md {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-content-pg-no-md {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }

    .qnn-content-arrow-md {
        padding: 24px 8px 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .qnn-main-md {
        display: none;
    }

    .qnn-title-row-md {
        display: flex;
        padding: 16px 20px 16px 18px;
    }

    .qnn-title-md {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-title-grow-md {
        flex-grow: 1;
    }

    .qnn-lower-section-md {
        padding: 8px 8px 0 8px;
        display: grid;
        grid-template-columns: auto auto;
    }

    .qnn-content-border-md {
        display: flex;
        width: 100%;
        height: 72px;
        cursor: pointer;
    }

    .qnn-content-border-md:hover {
        background-color: rgba(var(--theme-surface-main));
        border-radius: 4px;
    }

    .qnn-img-md {
        padding: 12px 0 12px 14px;
    }

    .qnn-content-inside-md {
        padding: 15px 0 0 22px;
    }

    .qnn-content-title-md {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(var(--theme-text-primary));
    }

    .qnn-content-pg-no-md {
        padding-top: 2px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(var(--theme-text-secondary));
    }

    .qnn-content-arrow-md {
        padding: 24px 8px 0 0;
    }
}

  .grid-container-recommendation{
    padding: 0;
    margin-left: -10px;
}

@media only screen and (min-width: 1200px) {
  .recommendation {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 64px;
  }

  .recommendation-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
    margin-top: 10px;
  }

  .hideonMdSm {
    display: block;
  }

  .grid-container-recommendation {
    width: 100%;
  }

  .first-div {
    /*margin-bottom: 50px;*/
    display: flex;
    margin-right: -85px;
  }

  .left-arrow-recommendation {
    width: 32px;
    height: 32px;
    background-color: white;
    box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.06);
    color: rgba(var(--theme-text-secondary));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 60px;
    left: -1172px;
  }

  .hide-large {
    display: none !important;
  }

  .next-btn-recommendation {
    position: relative;
    top: 50px;
    left: -60px;
    height: 54px;
    width: 54px;
  }

  .second-div {
    margin-bottom: 50px;
    display: grid;
    align-content: start;
  }
}

@media only screen and (max-width: 1200px) {
  .recommendation {
    margin-top: 64px;
  }

  .recommendation-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
  }

  .hideonMdSm {
    display: none;
  }


  .grid-container-recommendation {
    display: grid;
    grid-template-columns: 274px 274px 274px 274px 274px 274px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;
    padding-bottom: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .grid-container-recommendation::-webkit-scrollbar{
    display: none;
  }

  .first-div {
    margin-bottom: 32px;
    display: grid;
    align-content: start;
  }

  .second-div {
    margin-bottom: 100px;
    display: grid;
    align-content: start;
  }
}


  @media only screen and (max-width: 768px) {
    .recommendation {
      margin-top: 20px;
    }

    .recommendation-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(var(--theme-text-primary));
      margin-bottom: 32px;
    }

    .hideonMdSm {
      display: none;
    }


    .grid-container-recommendation {
      display: grid;
      grid-template-columns: 274px 274px 274px 274px 274px 274px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scroll-snap-type: x proximity;
      padding-bottom: 0px;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    .grid-container-recommendation::-webkit-scrollbar{
      display: none;
    }

    .first-div {
      margin-bottom: 32px;
      display: grid;
      align-content: start;
    }

    .second-div {
      margin-bottom: 100px;
      display: grid;
      align-content: start;
    }
  }




/* .header{
    text-align: left;
    padding-top: 32px;
    padding-left:  10px;
    padding-bottom: 22px;
    margin-left: clamp(0px, 126px, 5%);
}


.Scroll-Icon{
    border-radius: 40px;
    height: 36px;
    width: 36px;
    position: absolute;
    display: flex;
    z-index: 10;
    margin-top: 61px;
    align-self: flex-start;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.06);
}

#right.Scroll-Icon{
    right: 0;
}

.Scrollable-Container{
    display: flex;
    position: relative;
}

.Latest-Items-List{
    display: flex;
    position: relative;
    z-index: 2;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none; 
}

.Latest-Item{
    min-width: 274px;
}
.Latest-Item:first-child{
    margin-left: clamp(0px, 126px, 5%);
}


@media only screen and (max-width: 468px) {
    .header{
        text-align: left;
        padding-top: 16px;
        padding-left: 16px;
        padding-bottom: 6px;
        margin-left: 0px;
    }

    .Latest-Item:first-child{
        margin-left: 4px;
    }

    .Scroll-Icon{
        display: none;
    }
}
 */
#rootSupport{
    height:calc(100vh - 60px)

}
/* #rootSupport  .MuiPaper-rounded{
    border-radius: 16px;
} */

#innerRectChat{
    min-width:320px;
    /* height:calc(100% - 8vh); */
    border-radius:0;
    
}

@media only screen and (max-width:768px){
    #innerRect{
        width:80vw;
    }
}

.MuiTypography-h6{
    line-height: 24px;
}





:root {
    --button-bg: rgba(39, 174, 96, 0.12);
    --button-bg-hover: rgba(39, 174, 96, 0.25);
    --button-text: rgba(33, 150, 83, 1);
}

/* {
    margin: 0;
    box-sizing: border-box;
} */

/* body{
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
} */

.verification-container {
    width: 90%;
    /* margin: 0 20px; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: rgba(var(--theme-text-primary));
    margin-left: auto;
    margin-right: auto;
}

.verification-title {
    margin: 0;
    margin-top: 48px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.verification-content,
.verification-bold {
    margin-top: 32px;
    color: rgba(var(--theme-text-secondary));
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.verification-content {
    max-width: 392px;
}

.verification-content .verification-bold {
    font-weight: 500;
    color: rgba(var(--theme-text-primary));
}

.verification-btn {
    /*     max-width: max-content;
 */
    margin-top: 56px;
    padding: 16px 32px;
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    transition: background-color 300ms ease;
}

.verification-btn .ctn {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 16px;
}

.verification-btn:hover {
    background-color: var(--button-bg-hover);
}

@media screen and (min-width: 358px) {
    .verification-container {
        width: 80%;
    }
}

@media screen and (min-width: 768px) {
    .verification-container {
        width: 62vw;
    }
}
.top-container{
    width: 70%;
    min-height: 100vh;
    margin: auto;
}

.reset-container{
    max-width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 468px) {
    .top-container{
        width: 90%;
    }
}
.top-wrapper{
    width: 70%;
    min-height: 100vh;
    margin: auto;
}

.error-container{
    max-width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.fail-btn {
    margin-top: 56px;
    padding: 16px 32px;
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    transition: background-color 300ms ease;
}

.fail-btn .fail-ctn {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 16px;
}

.fail-btn:hover {
    background-color: var(--button-bg-hover);
}

@media screen and (max-width: 468px) {
    .top-wrapper{
        width: 90%;
    }
}
