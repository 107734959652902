.NoMatchPage-Main-Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.NoMatchPage-Img {
    width: 100%;
    height: 244px;
    display: flex;
    justify-content: center;
    margin: 32px 0;
}

.NoMatchPage-Text {
    width: 480px;
    height: 97px;
    left: 480px;
    top: 497px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 48px;
    margin-left: 16px;
    margin-right: 16px;
}

.NoMatchPage-Button {
    display: flex;
    justify-content: center;
    margin-bottom: 20vh;
}

@media only screen and (max-width: 480px) {
    .NoMatchPage-Text {
        width: auto;
        height: auto;
        font-size: 24px;
        line-height: 32px;
    }
}