
/*.courses-flex {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.courseTitle-flex {*/
/*    display: flex;*/
/*    justify-content:  center;*/
/*}*/

.Courses-Header{
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}

.Course-Title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    margin-bottom: 32px;
}