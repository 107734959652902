#smallthumbnail-selected {
  background-color: rgba(var(--theme-surface-main));
}

.SmallThumbnail{
  position: relative;
}

.Nesofuel-overlay{
  position: absolute;
  top: calc(50% - 32px);
  left: 0;
}

@media only screen and (min-width: 1200px) {
  .SmallThumbnail {
    height: 103px;
    width: 372px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1;
  }
  .Img-SmallThumbnail {
    width: 176px;
    height: 99px;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    margin: 0 4px 3px 0;
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 176px;
    height: 99px;
    position: relative;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .progress-bar {
    position: absolute;
    height: 4px;
    bottom: -3px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(var(--theme-divider));
  }
  #progress {
    position: absolute;
    /*width: 60%;*/
    left: 0;
    height: 4px;
    background-color: rgba(255, 65, 23, 1);
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 400;
    flex-grow: 4;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 96px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(var(--theme-text-primary));
  }
}

@media only screen and (max-width: 800px) {
  .SmallThumbnail{
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    background-color: rgba(0, 0, 0, 0.87);
    margin: 0 4px 13px 0;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .progress-bar {
    position: absolute;
    height: 4px;
    bottom: 6px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(var(--theme-divider));
  }
  #progress {
    position: absolute;
    width: 60%;
    left: 0;
    height: 4px;
    background-color: rgba(255, 65, 23, 1);
  }
  .SmallThumbnail .More-Icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .SmallThumbnail .content {
    width: 300px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 700px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.04);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin: 0 4px 13px 0;
    justify-content: center;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .SmallThumbnail .duration {
    /*width: 10px;*/
    border-bottom: 4px solid red;
    margin-top: -5px;
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    width: 300px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 400px) {
  .SmallThumbnail {
    height: 103px;
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  .SmallThumbnail:hover {
    background-color: rgba(33, 33, 33, 0.08);
    transition: 0.3s;
  }
  .SmallThumbnail div {
    flex: 1;
  }
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin: 0 4px 13px 0;
    justify-content: center;
    border-radius: 4px;
    opacity: 0.9;
  }
  .img {
    width: 160px;
    height: 90px;
    position: relative;
  }
  .Img-SmallThumbnail {
    width: 160px;
    height: 90px;
  }
  .video-duration p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .SmallThumbnail .img {
    margin-right: 20px;
    width: 176px;
    height: 100px;
  }
  .SmallThumbnail .duration {
    /*width: 10px;*/
    border-bottom: 4px solid red;
    margin-top: -5px;
  }
  .SmallThumbnail .More-Icon {
    color: rgba(var(--theme-text-secondary));
  }
  .SmallThumbnail .content {
    width: 100%;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(var(--theme-text-primary));
    flex-grow: 4;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}