.Mui-checked {
  color: #018786;
}

.MuiIconButton-colorSecondary:hover {
/*   background-color: rgba(0, 245, 245, 0.04) !important;
 */}

.recent-payment-status{
  margin-top: 8px;
}
