.top-wrapper{
    width: 70%;
    min-height: 100vh;
    margin: auto;
}

.error-container{
    max-width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.fail-btn {
    margin-top: 56px;
    padding: 16px 32px;
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    transition: background-color 300ms ease;
}

.fail-btn .fail-ctn {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 16px;
}

.fail-btn:hover {
    background-color: var(--button-bg-hover);
}

@media screen and (max-width: 468px) {
    .top-wrapper{
        width: 90%;
    }
}