.top-container{
    width: 70%;
    min-height: 100vh;
    margin: auto;
}

.reset-container{
    max-width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 468px) {
    .top-container{
        width: 90%;
    }
}