.aboutContent {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  padding-top: 84px;
  padding-bottom: 193px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
}
.contentWrapper > h6 {
  padding-bottom: 32px;
}
.imgContent > img {
  padding-bottom: 40px;
}
.logoWrapper {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aboutLogoCluster {
  width: 216px;
  display: flex;
  justify-content: space-between;
}
h2 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.contentWrapper > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.logoWrapper > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: 0.15px;

  /* 00. On Surface / High Emphasis */

  color: rgba(var(--theme-text-primary));
}
.contentsec {
  margin-top: 32px !important;
  color: rgba(var(--theme-text-secondary));
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .aboutContent {
    margin: 0 5%;
    width: 90%;
  }
}
@media only screen and (max-width: 468px) {
  .aboutContent {
    height: auto;
    padding-bottom: 72px;
  }
  .contentWrapper:nth-child(2),
  .contentWrapper:nth-child(3),
  .logoWrapper {
    margin-top: 32px;
  }
}
