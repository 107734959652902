/* .Support {
  width: 75%;
  margin: 0 12.5%;
} */

.supportContent>p:nth-child(1) {
  color: rgba(var(--theme-text-primary));
  font-size: 34px;
  margin-top: 72px;
  line-height: 36px;
  /* identical to box height, or 106% */
}

.supportRightCluster>img {
  margin-left: 10%;
}

.supportContent>p:nth-child(2) {
  width: 65%;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  margin-top: 24px;
  margin-bottom: 171px;

  letter-spacing: 0.15px;

  /* 00. On Surface / Medium Emphasis */

  color: rgba(var(--theme-text-secondary));
}

.supportRightCluster {
  margin-top: 111px;
  /* display: flex;
  flex-direction: column; */
}

.supportBox {
  width: 100%;
  height: 23%;
  border: 1px solid rgba(var(--theme-divider));
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  transition: transform 300ms ease;
}

.supportBox:nth-child(1) {
  margin-top: 82px !important;
}

.supportBox:nth-child(3) {
  cursor: default;
}

.supportBox:nth-child(3):hover {
  transform: none;
}

.supportBox:hover {
  transform: scale(1.025);
}

.supportBoxContent {
  margin: 0 32px;
}

.supportBoxContent>img:nth-child(1) {
  margin-top: 32px;
}

/* .supportBoxContent > svg:nth-child(2) {  
  position: absolute;
  top: 20px;
  right: 20px;
} */
.supportBoxContent>a {
  position: absolute;
  top: 20px;
  right: 20px;
}

.supportBoxBody {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  letter-spacing: 0.25px;
  color: rgba(var(--theme-text-secondary));
}

.supportBoxContent>p:nth-child(2) {
  margin-top: 16px !important;
}

.support-mode-title {
  color: rgba(var(--theme-text-primary));
}

.paytmWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#PaytmQR {
  background: white;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media only screen and (max-width: 468px) {
  .supportBox {
    width: 100% !important;
    height: 33% !important;
  }

  .supportRightCluster>img {
    height: 148px;
    width: 132px;
  }

  .supportContent>p:nth-child(1) {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.15px;

  }

  .supportBox:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 16px !important;
  }

  .supportBox:nth-child(3) {
    margin-left: 0 !important;
    margin-top: 16px !important;
  }

  .supportLeftCluster {
    justify-content: center;
  }

  .supportLeftCluster {
    margin-top: 32px !important;
  }

}

@media only screen and (max-width: 768px) {
  .supportBox:nth-child(1) {
    margin-top: 0px !important;
  }

  .Support {
    width: 90%;
    margin: 0 5%;
  }

  .supportBox {
    width: 46.5%;
    /* min-width: 40%; */
    margin-top: 0;
    height: 45%;
  }

  .supportBox:nth-child(3) {
    height: 212px;
  }

  .supportBoxContent {
    margin: 0 29.95px;
  }

  .supportBoxBody:nth-child(3) {
    width: 154px !important;
  }

  .supportBox:nth-child(2) {
    margin-left: 5.67%;
  }

  .supportBox:nth-child(3) {
    margin-top: 20px;
    margin-bottom: 56px;
  }

  .supportRightCluster {
    margin-top: 56px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .supportRightCluster>img {
    margin-left: 0;
  }

  .supportContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .supportContent>p:nth-child(1) {
    margin-top: 48px;
  }

  .supportLeftCluster {
    margin-top: 56px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .supportContent>p:nth-child(2) {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

/* @media screen and (max-width: 5000px) and (min-width: 1800px) {
  .Support {
    width: 50%;
    margin: 0 25%;
  }
} */