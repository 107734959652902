.Secondary-outline1 {
  width: 93px;
  height: 36px;
  border: 1px solid var(--theme-secondary-main) !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px !important;
  text-transform: uppercase;
  color: var(--theme-secondary-main) !important;
  margin: 10px;
}

.Secondary-outline1:hover {
  width: 93px;
  height: 36px;
  border: 1px solid var(--theme-secondary-main);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgba(1, 135, 134, 0.07);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--theme-secondary-main);
}
