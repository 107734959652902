.empty-search {
    height: 100vh;
    width: 100%;
    position: relative;
    color: rgba(var(--theme-text-primary))
}

.empty-search-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.empty-search-message img {
    margin-bottom: 16px;
}

@media screen and (max-width:480px) {
    .empty-search-message{
        left: 16px;
        transform: none;
    }
}