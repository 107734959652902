:root {
    --button-bg: rgba(39, 174, 96, 0.12);
    --button-bg-hover: rgba(39, 174, 96, 0.25);
    --button-text: rgba(33, 150, 83, 1);
}

/* {
    margin: 0;
    box-sizing: border-box;
} */

/* body{
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
} */

.verification-container {
    width: 90%;
    /* margin: 0 20px; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: rgba(var(--theme-text-primary));
    margin-left: auto;
    margin-right: auto;
}

.verification-title {
    margin: 0;
    margin-top: 48px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.verification-content,
.verification-bold {
    margin-top: 32px;
    color: rgba(var(--theme-text-secondary));
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.verification-content {
    max-width: 392px;
}

.verification-content .verification-bold {
    font-weight: 500;
    color: rgba(var(--theme-text-primary));
}

.verification-btn {
    /*     max-width: max-content;
 */
    margin-top: 56px;
    padding: 16px 32px;
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    transition: background-color 300ms ease;
}

.verification-btn .ctn {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 16px;
}

.verification-btn:hover {
    background-color: var(--button-bg-hover);
}

@media screen and (min-width: 358px) {
    .verification-container {
        width: 80%;
    }
}

@media screen and (min-width: 768px) {
    .verification-container {
        width: 62vw;
    }
}